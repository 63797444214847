import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { getFullLibrary, getFolder } from '~/api/library';

import Card from '~/components/card';
import LibraryFolder from '~/components/library-folder';
import LibraryFile from '~/components/library-file';
import LibraryLink from '~/components/library-link';
import LoadingMessage from '~/components/loading-message';
import Breadcrumb from '~/components/breadcrumb';

import './styles.scss';
import Modal from '~/components/modal';
import renderHTML from 'react-render-html';

const LibrarySection = ({ sectionTitle, className, children }) => {
  return (
    <>
      {sectionTitle && <h3 className="section-title">{sectionTitle}</h3>}
      <section className={className}>{children}</section>
    </>
  );
};

const initialState = {
  folders: [],
  files: [],
  links: []
};

function Library({ organization: { activeOrganizationId } }) {
  const [loadedData, setLoadedData] = useState(false);
  const [libraryData, setLibraryData] = useState({ ...initialState });
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [showViewLink, setShowViewLink] = useState(false);
  const onViewClose = useCallback(() => setShowViewLink(null));
  const [modalContent, setModalContent] = useState(null);
  const distributeLibraryData = (data, folderId) => {
    const newLibraryData = {
      folders: [],
      files: [],
      links: []
    };

    data.results.forEach(item => {
      if (!item.data_content) {
        newLibraryData.folders.push(item);
      } else {
        if (item.data_content.type === 'page') {
          newLibraryData.links.push(item);
        } else {
          newLibraryData.files.push(item);
        }
      }
    });

    const libraryLink = {
      id: null,
      title: 'Biblioteca',
      route: '/library'
    };

    if (folderId) {
      setBreadcrumbData([
        libraryLink,
        ...data.obj.breadcrumb,
        {
          id: folderId,
          title: data.obj.title
        }
      ]);
    } else {
      setBreadcrumbData([libraryLink]);
    }

    setLibraryData(newLibraryData);
    setLoadedData(true);
  };

  useEffect(() => {
    const folderId = window.location.pathname.substr(9);

    if (activeOrganizationId) {
      setLoadedData(false);

      if (folderId) {
        getFolder(folderId, activeOrganizationId).then(({ data }) => {
          distributeLibraryData(data, folderId);
        });
      } else {
        getFullLibrary(activeOrganizationId).then(({ data }) => {
          distributeLibraryData(data);
        });
      }
    }
  }, [activeOrganizationId, window.location.pathname]);

  return (
    <>
      {showViewLink && (
        <Modal
          className="modal"
          overlayClassName="modal-overlay"
          onClose={onViewClose}
        >
          {modalContent && (
            <div className="modal-link">
              <h3 className="text-center">{modalContent.title}</h3>
              {/*<p className="text-center">{modalContent.description}</p>*/}
              <div>{renderHTML(modalContent.content)}</div>
            </div>
          )}
        </Modal>
      )}
      <div className="library-screen">
        {!loadedData && <LoadingMessage />}

        {loadedData && (
          <Card>
            <div className="library-header">
              <h1>Biblioteca</h1>
            </div>
            <div className="library-content">
              <Breadcrumb
                links={breadcrumbData.map(item => {
                  if (!item.route) {
                    item.route = `/library/${item.id}`;
                  }
                  return item;
                })}
              />

              {libraryData.folders.length > 0 && (
                <LibrarySection className="folders">
                  {libraryData.folders.map(folder => (
                    <LibraryFolder
                      key={folder.id}
                      title={folder.title}
                      folderId={folder.id}
                    />
                  ))}
                </LibrarySection>
              )}

              {libraryData.files.length > 0 && (
                <LibrarySection className="files">
                  {libraryData.files.map(file => (
                    <LibraryFile
                      key={file.id}
                      title={file.title}
                      extension={file.data_content.extension}
                      size={file.data_content.size}
                      file={file.url_access_content}
                      downloadFile={file.data_content.download_file}
                      image={file.data_content.cover}
                      createdAt={file.created_at}
                    />
                  ))}
                </LibrarySection>
              )}

              {libraryData.links.length > 0 && (
                <LibrarySection className="links">
                  {libraryData.links.map(link => (
                    <LibraryLink
                      key={link.id}
                      title={link.title}
                      url={link.url_access_content}
                      image={link.data_content.cover_128x0}
                      createdAt={link.created_at}
                      setModalContent={setModalContent}
                      setShowViewLink={setShowViewLink}
                    />
                  ))}
                </LibrarySection>
              )}

              {loadedData &&
                libraryData.folders.length === 0 &&
                libraryData.files.length === 0 &&
                libraryData.links.length === 0 && (
                  <h3 className="empty-folder">
                    Não há itens para serem exibidos para você aqui.
                  </h3>
                )}
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ organization }) => ({ organization });

export default connect(mapStateToProps)(Library);
