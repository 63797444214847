import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CRISP_WEBSITE_ID } from '~/settings';

import 'sanitize.css';
// import 'circular-std';
import CookiesBanner from '~/components/cookies-banner';
import Close from '~/components/close';
import Loading from '~/components/loading';
import MessageBar from '~/components/message-bar';
import { closeMessage } from '~/store/ducks/messageBar';
import Routes from '../routes';
import BodySetVersion from './body-set-version';
import SiteTitle from './site-title';
import LoadLinks from './load-links';
import './style.scss';
import { Custom } from './custom';
import { getConfig, approximateColor1ToColor2ByPercent } from '~/utils';

const App = ({
  loading,
  messageBar,
  errorMessage,
  closeMessage,
  organization: { activeOrganizationId },
  account
}) => {
  const hideCookiePolicy = localStorage.getItem('@webapp/hide-cookie-policy');

  const [showCookieBanner, setShowCookieBanner] = useState(
    !hideCookiePolicy || hideCookiePolicy === 'false'
  );
  // const [primaryColor, setPrimaryColor] = useState(null);
  // const [secondaryColor, setSecondaryColor] = useState(null);
  // const [secondaryNavColor, setSecondaryNavColor] = useState(null);
  // const [tertiaryColor, setTertiaryColor] = useState(null);

  const closeCookiesBanner = () => {
    localStorage.setItem('@webapp/hide-cookie-policy', true);
    setShowCookieBanner(false);
  };

  let primaryColor = null;
  let secondaryColor = null;

  if (account && account.data) {
    const config = getConfig(
      account.data.organization_is_active_set,
      activeOrganizationId
    );
    if (config.primaryColor) {
      primaryColor = config.primaryColor;
    }
    if (config.secondaryColor) {
      secondaryColor = config.secondaryColor;
    }
  }

  let secondaryNavColor = null;
  let tertiaryColor = null;

  if (primaryColor) {
    secondaryNavColor = approximateColor1ToColor2ByPercent(
      primaryColor,
      '#cccccc',
      0.7
    );
  }

  if (secondaryColor) {
    tertiaryColor = approximateColor1ToColor2ByPercent(
      secondaryColor,
      '#000000',
      0.5
    );
  }

  const crispStart = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

    (function() {
      var d = document;
      var s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  };

  useEffect(() => {
    crispStart();
  }, [activeOrganizationId]);

  return (
    <div className="app">
      <Custom
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        secondaryNavColor={secondaryNavColor}
        tertiaryColor={tertiaryColor}
      >
        <LoadLinks />
        <SiteTitle />
        <BodySetVersion />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Routes />
          </Switch>
        </BrowserRouter>
        <Loading visible={loading} />

        {showCookieBanner && <CookiesBanner onClose={closeCookiesBanner} />}

        {messageBar.message && (
          <MessageBar
            fixed
            top
            left
            right
            color={messageBar.color}
            timeoutMilisseconds={messageBar.timeoutMilisseconds}
          >
            <span>{messageBar.message}</span>
            <Close onClick={closeMessage} />
          </MessageBar>
        )}
      </Custom>
    </div>
  );
};

const mapStateToProps = store => ({
  loading: store.loading.loading,
  messageBar: store.messageBar,
  organization: store.organization,
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeMessage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
