import React from 'react';
import MaskedInput from 'react-text-mask';
import './style.scss';

const Input = ({
  invalid,
  textCenter,
  small,
  textarea,
  rows = 5,
  ...props
}) => {
  const classes = ['input'];
  if (invalid) classes.push('invalid');
  if (textCenter) classes.push('text-center');
  if (small) classes.push('small');

  if (props.name) {
    classes.push(`input-${props.name}`);
  }

  const data = {
    className: classes.join(' '),
    type: 'text',
    ...props
  };

  if (props.mask) {
    return <MaskedInput {...data} />;
  }

  if (textarea) {
    return <textarea rows={rows} {...data} />;
  }

  return <input {...data} />;
};

export default Input;
