import React, { useState } from 'react';

import Button from '~/components/button';
import Icon from '~/components/icon';
import LimitedTextarea from '~/components/limited-textarea';

import startIcon from '~/assets/images/star-model.svg';
import pointIcon from '~/assets/images/point-model.svg';

import StyledPerformanceQuestionPreviousCycle from './styles';

function PerformanceQuestionPreviousCycle({
  questionNumber,
  statement,
  modelImage,
  options,
  answer,
  descriptiveAnswer,
  openAnswerQuestion,
  answerByManaged,
  descriptiveAnswerByManaged,
  questions,
  setQuestions,
  disabled
}) {
  const [showAnswerByManaged, setShowAnswerByManaged] = useState(false);
  const models = { radio: pointIcon, star: startIcon };

  const handleDescriptiveAnswer = ({ target }) => {
    setQuestions(() => {
      const currentQuestions = [...questions];
      currentQuestions[questionNumber - 1].open_answer = target.value;
      return currentQuestions;
    });
  };

  return (
    <StyledPerformanceQuestionPreviousCycle>
      <div className="question-header">
        <div className="info">
          <div className="question-number">{questionNumber}</div>
          <p className="statement">{statement}</p>
        </div>
        <div className="actions">
          {!showAnswerByManaged ? (
            <Button
              color="white"
              onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
              disabled={!answerByManaged}
            >
              <Icon name="eye" />
              Ver respostas do avaliado
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
            >
              <Icon name="not-eye" />
              Ocultar respostas do avaliado
            </Button>
          )}
        </div>
      </div>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answer === option.pk ? ' selected' : ''}
              ${
                answerByManaged === option.pk && showAnswerByManaged
                  ? ' answer-by-managed'
                  : ''
              }
            `}
          >
            <img src={models[modelImage]} alt="model" />
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
      {descriptiveAnswerByManaged && showAnswerByManaged && (
        <div className="descriptive-answer-by-managed">
          <h5>{openAnswerQuestion} • Resposta do Avaliado</h5>
          <p>{descriptiveAnswerByManaged}</p>
        </div>
      )}
      {openAnswerQuestion && (
        <div className="descriptive-answer">
          <h5>{openAnswerQuestion}</h5>
          <LimitedTextarea
            limit={1000}
            rows={1}
            value={descriptiveAnswer || ''}
            onChange={handleDescriptiveAnswer}
            placeholder="Insira sua resposta"
            disabled
          />
        </div>
      )}
    </StyledPerformanceQuestionPreviousCycle>
  );
}

export default PerformanceQuestionPreviousCycle;
