import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { getComments, postComment, postPoll } from '~/api/post';

import { addWololo } from '~/services/tasks';

import store from '~/store';
import { closePostModal } from '~/store/ducks/post-modal';

import PostHeader from '../post-header';
import PostImage from '../post-image';

import './style.scss';

class ViewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: '',
      loaded: false,
      showShare: false,
      newComment: '',
      showComment: false,
      comments: [],
      poll_no_percent: null,
      poll_yes_percent: null,
      yes: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showShare = this.showShare.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.changePoll = this.changePoll.bind(this);
  }

  componentDidMount() {
    this.setState({
      poll_yes_percent: parseInt(this.props.data.poll_yes_percent, 10),
      poll_no_percent: parseInt(this.props.data.poll_no_percent, 10),
      yes:
        this.props.data.me_poll !== null
          ? this.props.data.me_poll === 'yes'
          : null
    });

    if (this.props.data.can_comment) {
      getComments(this.props.data.id)
        .then(({ data }) => {
          const { next, results } = data;

          const parseDate = date => `${moment(date).fromNow(true)} atrás`;

          const parsedResult = results.map(comment => ({
            ...comment,
            created_at: parseDate(comment.created_at),
            accountcomment_set: comment.accountcomment_set.map(sub => ({
              ...sub,
              created_at: parseDate(sub.created_at)
            }))
          }));

          this.setState({
            loaded: true,
            comments: parsedResult,
            next
          });
        })
        .catch(e => {
          this.setState({ loaded: true });
          console.error(e);
        });
    }
  }

  handleChange({ target }) {
    this.setState({ newComment: target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    postComment(this.props.data.id, this.state.newComment)
      .then(({ data }) => {
        const { id } = this.props.data.account.data;
        const {
          name,
          avatar,
          avatar_128x0
        } = this.props.data.account.data.profile;

        data.created_at = `${moment(data.created_at).fromNow(true)} atrás`;

        data.owner = {
          id,
          name,
          avatar,
          avatar_128x0
        };

        this.setState(({ comments }) => ({
          comments: [data, ...comments]
        }));

        this.hideOptions();
      })
      .catch(e => {
        console.error(e);
        alert('Erro');
      });
  }

  showShare() {
    this.setState({
      showShare: true,
      showComment: false
    });
  }

  hideOptions() {
    this.setState({
      showShare: false,
      newComment: '',
      showComment: false
    });
  }

  changePoll(value) {
    postPoll(this.props.data.id, value)
      .then(({ data: { poll_yes_percent, poll_no_percent, yes } }) => {
        this.setState({ poll_no_percent, poll_yes_percent, yes });
      })
      .catch(e => {
        console.error(e);
        alert('Erro');
      });
  }

  setShowComments = () => {
    this.setState({ showComment: true });
  };

  build_url = embed_id => {
    return `https://cdnapisec.kaltura.com/p/2601552/sp/0/playManifest/entryId/${embed_id}/format/url/protocol/https/flavorParamId/0/video.mp4`;
  };

  render() {
    const postHTML = renderHTML(this.props.data.content);

    // we should remove <style> from content before show
    // it is set for mobile apps, not us (webapp)
    // tip: we are not ready here if multiple <style> appears
    if (postHTML && Array.isArray(postHTML)) {
      const fixedStyleIndex = postHTML.findIndex(e => e.type === 'style');
      if (fixedStyleIndex > -1) postHTML.splice(fixedStyleIndex, 1);
    }

    return (
      <div className="view-post">
        <PostHeader
          info={
            this.props.data.channel ? this.props.data.channel : 'Publicação'
          }
          points={this.props.data.wololo_points}
          organization={this.props.data.organization}
          meTrail={this.props.data.me_trail}
          expire={this.props.data.close_at}
          me_create_wololo={this.props.data.me_create_wololo}
          channel={
            this.props.data.channel ? this.props.data.channel : 'publishing'
          }
        />
        <div className="card-body">
          {this.props.data.image && <PostImage url={this.props.data.image} />}
          <h3 className="post-title text-center">{this.props.data.title}</h3>
          {this.props.data.description && (
            <p className="post-description text-center">
              {this.props.data.description}
            </p>
          )}

          {this.props.data.content && (
            <div className="post-html default-scroll">{postHTML}</div>
          )}

          {this.props.data.wololo_target_embedded && (
            <div className="kaltura-movie">
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                      onContextMenu: e => e.preventDefault()
                    }
                  }
                }}
                controls
                playbackRate
                onContextMenu={e => e.preventDefault()}
                onEnded={() => {
                  store.dispatch(closePostModal());
                  addWololo(this.props.data.id);
                  this.props.data.onFinish();
                }}
                url={this.build_url(this.props.data.wololo_target_embedded)}
              />
            </div>
          )}

          {this.props.data.updated_at && (
            <div className="updated-at">
              <small>
                <i>Atualizado em </i>
                <i>
                  {moment(this.props.data.updated_at).format(
                    'D [de] MMM [de] YYYY [às] HH:mm'
                  )}
                </i>
              </small>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(ViewPost);
