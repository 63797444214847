import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useDispatch } from 'redux-react-hook';

import {
  getFeedback,
  updateFeedback,
  getPDIs,
  sendFeedback
} from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';
import {
  openDefaultModal,
  closeDefaultModal
} from '~/store/ducks/default-modal';

import { deepClone } from '~/utils';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SuspendedMenu from '~/components/suspended-menu';
import CollaboratorCard from '~/components/collaborator-card';
import Fab from '~/components/fab';
import CustomLoading from '~/components/custom-loading';
import PendingModificationModal from '~/components/pending-modification-modal';
import Card from '~/components/card';
import PerformanceFeedbackQuestion from '~/components/performance-feedback-question';
import GenericTable from '~/components/generic-table';
import PDIRegisterModal from '~/components/pdi-register-modal/pdi-register-modal';
import PDIConfirmRemoveModal from '~/components/pdi-confirm-remove-modal';
import ConfirmModal from '~/components/confirm-modal';

import feedbackHeaderImage from '~/assets/images/feedback-header-image.svg';

import { StyledPerformanceFeedback } from './styles';

let changeControl = {};

function PerformanceFeedback({
  organization,
  message: { showMessage },
  closeDefaultModal,
  location,
  history
}) {
  const { activeOrganizationId } = organization;
  const targetId = location.pathname
    .split('/competence/')[0]
    .split('/feedback/')[0]
    .replace('/performance/', '');
  const feedbackId = location.pathname.split('/feedback/')[1];

  const dispatch = useDispatch();

  // Feedback states
  const [feedbackData, setFeedbackData] = useState({});
  const [questions, setQuestions] = useState([
    { pk: 1, title: 'Pontos positivos', can_answer: true, answer: '' },
    { pk: 2, title: 'Pontos negativos', can_answer: true, answer: '' },
    { pk: 3, title: 'Fraquezas', can_answer: true, answer: '' },
    { pk: 4, title: 'Oportunidades', can_answer: true, answer: '' },
    { pk: 5, title: 'Observações - Avaliador', can_answer: true, answer: '' },
    { pk: 6, title: 'Observações - Avaliado', can_answer: false, answer: '' }
  ]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [feedbackReadOnly, setFeedbackReadOnly] = useState(true);
  const [hasPDI, setHasPDI] = useState(true);

  // PDI states
  const [PDIColumns, setPDIColumns] = useState([
    { key: '1', value: 'Competência' },
    { key: '2', value: 'Lacuna' },
    { key: '3', value: 'Ação' },
    { key: '4', value: '70/20/10' },
    { key: '5', value: 'Prazo (semanas)' }
  ]);
  const [PDIRows, setPDIRows] = useState([]);
  const [PDICount, setPDICount] = useState(0);
  const [PDICurrentPage, setPDICurrentPage] = useState(1);
  const [PDIPageList, setPDIPageList] = useState([]);
  const [PDILoading, setPDILoading] = useState(true);
  const [PDIReadOnly, setPDIReadOnly] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePendingChanges = route => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal onAction={() => history.push(route)} />
      )
    );
  };

  const handleBack = () => {
    const currentState = [...questions];

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges('/performances');
      return;
    }

    history.push('/performances');
  };

  const getSaveData = () => {
    const questionList = questions.map(question => ({
      question_id: question.pk,
      text: question.answer || ''
    }));

    return {
      answersquestions: questionList
    };
  };

  const handleSave = () => {
    setSaveLoading(true);

    const data = getSaveData();

    updateFeedback(activeOrganizationId, targetId, feedbackId, data)
      .then(() => {
        showMessage('Feedback salvo!', 'success', 1000);

        changeControl = deepClone([...questions]);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao salvar o feedback.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title="Enviar feedback?"
          description="Tem certeza que deseja enviar este feedback?"
          confirmButtonText="Enviar"
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = () => {
    setSendLoading(true);
    const data = getSaveData();
    closeDefaultModal();

    updateFeedback(activeOrganizationId, targetId, feedbackId, data)
      .then(() => {
        changeControl = deepClone([...questions]);
        sendFeedback(activeOrganizationId, targetId, feedbackId)
          .then(() => {
            showMessage('Seu feedback foi enviado.', 'success');
            history.push('/performances');
          })
          .catch(error => {
            if (error.response.status === 400) {
              showMessage(error.response.data.errors[0], 'danger');
              return;
            }

            history.push('/unexpected-error');
          })
          .finally(() => {
            setSendLoading(false);
          });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao enviar o feedback.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const showPDIRegisterModal = (feedback, PDIId, page = 1) => {
    dispatch(
      openDefaultModal(
        <PDIRegisterModal
          pdiId={PDIId || null}
          feedback={feedback}
          targetId={targetId}
          feedbackId={feedbackId}
          getPDIList={getPDIList}
          currentPage={page}
        />
      )
    );
  };

  const showPDIConfirmRemoveModal = (
    PDIId,
    feedback,
    page = 1,
    numberOfRows
  ) => {
    dispatch(
      openDefaultModal(
        <PDIConfirmRemoveModal
          pdiId={PDIId || null}
          feedback={feedback}
          targetId={targetId}
          feedbackId={feedbackId}
          getPDIList={getPDIList}
          currentPage={page}
          numberOfRows={numberOfRows}
        />
      )
    );
  };

  const editPDI = (PDIId, feedback, page) => {
    showPDIRegisterModal(feedback, PDIId, page);
  };

  const removePDI = (PDIId, feedback, page, numberOfRows) => {
    showPDIConfirmRemoveModal(PDIId, feedback, page, numberOfRows);
  };

  const drawMoreOptions = (rowId, readOnly, feedback, page, numberOfRows) => {
    return (
      <SuspendedMenu>
        <Button
          color="primary"
          className="outline"
          disabled={saveLoading || sendLoading || readOnly}
          onClick={() => editPDI(rowId, feedback, page)}
        >
          {!saveLoading && 'Editar'}
          {saveLoading && (
            <CustomLoading type="spin" height={16} width={16} fluid />
          )}
        </Button>
        <Button
          color="primary"
          className="outline"
          disabled={saveLoading || sendLoading || readOnly}
          onClick={() => removePDI(rowId, feedback, page, numberOfRows)}
        >
          {!sendLoading && 'Remover'}
          {sendLoading && (
            <CustomLoading
              type="spin"
              height={16}
              width={16}
              fluid
              color="#FFFFFF"
            />
          )}
        </Button>
      </SuspendedMenu>
    );
  };

  const PDIRowsFactory = (PDIList, readOnly, feedback, page) => {
    const rows = PDIList.map(PDI => {
      const PDIId = PDI.id;
      delete PDI['id'];
      const cellsArray = Object.keys(PDI).map(key => PDI[key]);

      const cells = cellsArray.map((cell, index) => ({
        key: index + 1,
        value: cell || '-'
      }));

      if (!readOnly) {
        cells.push({
          key: parseInt(cells[cells.length - 1].key) + 1,
          value: drawMoreOptions(
            PDIId,
            readOnly,
            feedback,
            page,
            PDIList.length
          ),
          isCustomField: true
        });
      }

      return {
        key: PDIId,
        values: cells
      };
    });

    return rows;
  };

  const PDIPageListFactory = numberOfPages => {
    const pageList = [];
    for (let i = 0; i < numberOfPages; i += 1) {
      pageList.push({
        key: `${i + 1}`,
        value: i + 1
      });
    }
    return pageList;
  };

  const getPDIList = (page = 1, feedback) => {
    setPDILoading(true);

    getPDIs(activeOrganizationId, targetId, feedbackId, page)
      .then(response => {
        const { recordsTotal, num_pages, results, can_edit } = response.data;
        const PDIPages = PDIPageListFactory(num_pages);
        const PDIRows = PDIRowsFactory(results, !can_edit, feedback, page);

        const columns = [
          { key: '1', value: 'Competência' },
          { key: '2', value: 'Lacuna' },
          { key: '3', value: 'Ação' },
          { key: '4', value: 'Tipo de experiência' },
          { key: '5', value: 'Prazo (semanas)' }
        ];

        if (can_edit && feedback.can_edit) {
          columns.push({ key: '6', value: '' });
        }

        setPDICurrentPage(page);
        setPDICount(recordsTotal);
        setPDIPageList(PDIPages);
        setPDIColumns(columns);
        setPDIRows(PDIRows);
        setPDIReadOnly(!can_edit);
      })
      .catch(() => {
        showMessage(
          'Ocorreu um erro ao carregar a lista de PDIs.',
          'danger',
          3000
        );
      })
      .finally(() => {
        setPDILoading(false);
      });
  };

  const handlePageChange = page => {
    getPDIList(page, feedbackData);
  };

  const showPerformanceAnswers = event => {
    event.preventDefault();
    const currentState = [...questions];

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges(
        `/performance/${targetId}/feedback/${feedbackId}/competence/1`
      );
      return;
    }

    history.push(
      `/performance/${targetId}/feedback/${feedbackId}/competence/1`
    );
  };

  useEffect(() => {
    getFeedback(activeOrganizationId, targetId, feedbackId)
      .then(response => {
        const { questions: questionList } = response.data;

        setQuestions(questionList);

        changeControl = deepClone(questionList);

        delete response.data['questions'];
        setFeedbackData(response.data);
        setFeedbackReadOnly(!response.data.can_edit);

        setHasPDI(response.data.has_pdi);

        if (response.data.has_pdi) {
          getPDIList(1, response.data);
        }
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage('Este feedback não está disponível.', 'danger', 3000);
          history.push('/performances');
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledPerformanceFeedback>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          {!feedbackReadOnly && (
            <div className="right-side">
              <Button
                color="primary"
                className="outline"
                disabled={loading || saveLoading || sendLoading}
                onClick={handleSave}
              >
                {!saveLoading && 'Salvar'}
                {saveLoading && (
                  <CustomLoading type="spin" height={16} width={16} fluid />
                )}
              </Button>
              <Button
                color="primary"
                disabled={loading || saveLoading || sendLoading}
                onClick={confirmSend}
              >
                {!sendLoading && 'Enviar'}
                {sendLoading && (
                  <CustomLoading
                    type="spin"
                    height={16}
                    width={16}
                    fluid
                    color="#FFFFFF"
                  />
                )}
              </Button>

              <SuspendedMenu>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={handleSave}
                >
                  {!saveLoading && 'Salvar'}
                  {saveLoading && (
                    <CustomLoading type="spin" height={16} width={16} fluid />
                  )}
                </Button>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={confirmSend}
                >
                  {!sendLoading && 'Enviar'}
                  {sendLoading && (
                    <CustomLoading
                      type="spin"
                      height={16}
                      width={16}
                      fluid
                      color="#FFFFFF"
                    />
                  )}
                </Button>
              </SuspendedMenu>
            </div>
          )}
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="performance-header">
              {!feedbackReadOnly && (
                <p className="finish-date">
                  Envio disponível até{' '}
                  {moment(feedbackData.finish_at, 'DD/MM/YYYY').format(
                    'DD/MM/YYYY'
                  )}
                </p>
              )}

              <CollaboratorCard
                name={feedbackData.managed.name}
                avatar={feedbackData.managed.avatar_128x0}
                department={feedbackData.department}
                profile={feedbackData.managed.profile}
                manager={feedbackData.manager}
              >
                <div className="show-performance-wrapper">
                  {feedbackData.can_view_performance && (
                    <Link
                      to={`/performance/${targetId}/feedback/${feedbackId}/competence/1`}
                      className="outline"
                      onClick={showPerformanceAnswers}
                    >
                      Ver avaliação
                    </Link>
                  )}
                </div>
              </CollaboratorCard>
            </div>

            <Card className="feedback-form">
              <div className="custom-form-header">
                <img src={feedbackHeaderImage} alt="Feedback" />
                <div className="text-info">
                  <h1 className="title">Registre seu feedback</h1>
                  <p className="description">
                    Preencha detalhadamente as suas considerações em cada item e
                    realize um feedback construtivo, colocando em prática as
                    técnicas e informações passadas no treinamento. O nome do
                    quadrante do 9box do avaliado está disponível ao clicar em
                    "Ver avaliação", essa informação é um direcionamento para o
                    desenvolvimento, no entanto, conforme orientado, é
                    confidencial e proibida de ser compartilhada com outras
                    pessoas, inclusive seu liderado.
                  </p>
                </div>
              </div>

              {questions.map((question, index) => (
                <PerformanceFeedbackQuestion
                  key={question.pk}
                  currentQuestion={question}
                  questionNumber={index + 1}
                  questions={questions}
                  setQuestions={setQuestions}
                  disabled={!question.can_answer || feedbackReadOnly}
                />
              ))}

              {hasPDI && (
                <div className="pdi-question">
                  <div className="field-label">
                    <div className="question-number">
                      {questions.length + 1}
                    </div>
                    <p className="question">Registre o PDI:</p>
                  </div>
                  <GenericTable
                    tableActions={
                      <>
                        {!PDIReadOnly && !feedbackReadOnly && (
                          <Button
                            color="primary"
                            className="outline"
                            onClick={() => showPDIRegisterModal(feedbackData)}
                          >
                            Incluir ação
                          </Button>
                        )}
                      </>
                    }
                    status={
                      PDICount !== 1
                        ? `${PDICount} registros`
                        : `${PDICount} registro`
                    }
                    columns={PDIColumns}
                    rows={PDIRows}
                    cellsCharLimit={11}
                    showPagination
                    currentPage={PDICurrentPage}
                    pages={PDIPageList}
                    onPageChange={handlePageChange}
                    loading={PDILoading}
                  />
                </div>
              )}
            </Card>

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledPerformanceFeedback>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PerformanceFeedback));
