import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '~/components/icon';
import { Form, Field } from 'react-final-form';
import { InputFinalForm } from '~/components/form';
import {
  requiredValueValidator,
  sameFieldValueValidator,
  minDigitValidator,
  composeValidators
} from '~/components/form/validators';
import './style.scss';
import { showMessage } from '~/store/ducks/messageBar';
import { updatePassword } from '~/api/account';
import AccountMenu from './account-menu';
import Loading from '~/components/loading';
import { bindActionCreators } from 'redux';
import { openMessageModal } from '~/store/ducks/message-modal';
import { registerAccount } from '~/store/ducks/account';
import { getUrlToRedirect, removeUrlToRedirect } from '~/services/navigation';

const confirmValidator = composeValidators(
  requiredValueValidator,
  minDigitValidator(8),
  sameFieldValueValidator('new_password', 'Nova Senha')
);

const newPasswordValidator = composeValidators(
  requiredValueValidator,
  minDigitValidator(8)
);

const ChangePassword = ({ onSubmit, loading }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, form: { reset } }) => (
      <form onSubmit={handleSubmit}>
        <Loading visible={loading} />
        <div className="account-screen">
          <AccountMenu />
          <div className="section-wrapper">
            <h2>Mudar Senha</h2>
            <p>
              Esta seção permite que você altere a senha que dá acesso à sua
              conta.
            </p>
          </div>

          <div className="form-section section-wrapper">
            <div style={{ maxWidth: '500px', width: '100%', flex: 'initial' }}>
              <div className="input-group">
                <div>
                  <label>Senha atual</label>
                  <Field
                    name="old_password"
                    type="password"
                    component={InputFinalForm}
                    validate={requiredValueValidator}
                  />
                </div>
              </div>

              <div className="input-group">
                <div>
                  <label>Nova senha</label>
                  <Field
                    name="new_password"
                    type="password"
                    component={InputFinalForm}
                    validate={newPasswordValidator}
                  />
                </div>
              </div>

              <div className="input-group">
                <div>
                  <label>Confirme a nova senha</label>
                  <Field
                    name="new_password_confirm"
                    type="password"
                    component={InputFinalForm}
                    validate={confirmValidator}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section-wrapper mobile-buttons-full actions">
            <button
              onClick={reset}
              type="button"
              className="outline button secondary"
            >
              <Icon name="cancel-circle" /> Cancelar
            </button>

            <button className="button primary ml10">
              <Icon name="floppy-disk" /> Alterar senha
            </button>
          </div>
        </div>
      </form>
    )}
  />
);

class ChangePasswordEnhanced extends PureComponent {
  state = {
    loading: false
  };

  componentDidMount() {
    const { account, showMessage } = this.props;
    if (account.data && account.data.is_reseted_password) {
      showMessage('Atualize sua senha para continuar', 'danger');
    }
  }

  onSubmit = async ({ old_password, new_password }, form) => {
    const { showMessage, account, registerAccount } = this.props;

    this.setState({ loading: true });

    try {
      const resp = await updatePassword(old_password, new_password);

      if (_.get(resp, 'data.id')) {
        form.reset();
        showMessage('Senha atualizada com sucesso', 'secondary');
        registerAccount({ ...account.data, is_reseted_password: false });
        const urlToRedirect = getUrlToRedirect();
        if (urlToRedirect) {
          removeUrlToRedirect();
          this.props.history.push(urlToRedirect);
        }
      } else {
        return {
          new_password: 'Houve um erro desconhecido, tente mais tarde'
        };
      }
    } catch (e) {
      this.setState({ loading: false });

      if (_.get(e, 'response.data.code') === 'field_error') {
        const result = _.get(e, 'response.data.errors').reduce((memo, item) => {
          memo[item.field] = item.error;
          return memo;
        }, {});

        return result;
      } else {
        return {
          new_password: 'Houve um erro desconhecido, tente mais tarde'
        };
      }
    }

    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return <ChangePassword onSubmit={this.onSubmit} loading={loading} />;
  }
}

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { showMessage, openMessageModal, registerAccount },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePasswordEnhanced));
