import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';

import { getPDIs } from '~/api/pdi';

import { showMessage } from '~/store/ducks/messageBar';
import { openDefaultModal } from '~/store/ducks/default-modal';

import PageHeader from '~/components/page-header';
import Select from '~/components/form/select';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import CustomLoading from '~/components/custom-loading';
import { Check } from '~/components/form';
import PDIItem from '~/components/pdi-item';
import PDIDetailsModal from '~/components/pdi-details-modal';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Breadcrumb from '~/components/breadcrumb';
import Fab from '~/components/fab';
import NewPDIModal from '~/components/new-pdi-modal';

import { StyledPDIList } from './styles';

function PDIList({ organization, history, showMessage, location }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;
  const participantId = location.pathname.split('/pdi/')[1] || null;

  const dispatch = useDispatch();

  const [PDIList, setPDIList] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([
    { id: '1', title: 'PDIs', route: '/pdi' },
    { id: '2', title: 'Minha equipe', route: '/pdi/my-team' }
  ]);
  const [learningModel, setLearningModel] = useState([]);

  const [status, setStatus] = useState('all');
  const [statusList, setStatusList] = useState([]);
  const [isLateCheck, setIsLateCheck] = useState(false);

  const [order, setOrder] = useState('start_at');

  const getPDIList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getPDIs(activeOrganizationId, filters, page, participantId)
      .then(response => {
        setIsManager(response.data.is_manager);
        setPDIList(() => response.data.results);
        setNextPage(response.data.next);
        setStatusList(response.data.status);
        if (participantId) {
          setBreadcrumbLinks(lastBreadcrumbLinks => {
            lastBreadcrumbLinks[2] = {
              id: '3',
              title: response.data.account,
              route: null
            };
            return lastBreadcrumbLinks;
          });
        }
        setLearningModel(response.data.learning_model);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatus = event => {
    const { value } = event.target;

    getPDIList({
      status: value !== 'all' ? value : '',
      order,
      is_late: isLateCheck
    });

    setStatus(value);
  };

  const handleOrder = event => {
    const { value } = event.target;

    getPDIList({
      status: status !== 'all' ? status : '',
      order: value,
      is_late: isLateCheck
    });

    setOrder(value);
  };

  const handleIsLateCheck = event => {
    const { checked } = event.target;

    getPDIList({
      status: status !== 'all' ? status : '',
      order,
      is_late: checked
    });

    setIsLateCheck(checked);
  };

  const openPDIDetails = id => {
    dispatch(
      openDefaultModal(
        <PDIDetailsModal
          orgId={activeOrganizationId}
          pdiId={id}
          participantId={participantId}
          changeContentCallback={() =>
            getPDIList({
              status: status !== 'all' ? status : '',
              order,
              is_late: isLateCheck
            })
          }
        />,
        '1000px'
      )
    );
  };

  const showNewPDIModal = () => {
    dispatch(
      openDefaultModal(
        <NewPDIModal
          pdiSettings={{
            learning_model: learningModel
          }}
          organization={organization}
          participantId={participantId}
          getPDIList={() => {
            setStatus('all');
            setOrder('start_at');
            setIsLateCheck(false);
            getPDIList({
              status: '',
              order: 'start_at',
              is_late: false
            });
          }}
        />
      )
    );
  };

  const loadMorePDIs = () => {
    setLoadingMore(true);

    const filters = {
      status: status !== 'all' ? status : '',
      order,
      is_late: isLateCheck
    };

    getPDIs(activeOrganizationId, filters, currentPage + 1, participantId)
      .then(response => {
        setPDIList(lastPDIList => [...lastPDIList, ...response.data.results]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getPDIList({
      status: status !== 'all' ? status : '',
      order,
      is_late: isLateCheck
    });
  }, [activeOrganizationId, participantId]);

  return (
    <StyledPDIList>
      {participantId && (
        <Button
          color="primary"
          className="link"
          onClick={() => history.push('/pdi/my-team')}
        >
          <Icon name="back" />
          Voltar
        </Button>
      )}

      <PageHeader>
        <div className="text-info">
          <h1>PDIs</h1>
          {!loading && participantId && (
            <Breadcrumb links={breadcrumbLinks} compressionStart={10} />
          )}
        </div>
        {isManager && !participantId && (
          <Button color="primary" onClick={() => history.push('/pdi/my-team')}>
            <Icon name="connections" />
            Minha equipe
          </Button>
        )}
      </PageHeader>

      <div className="filters">
        <Check
          type="checkbox"
          name="anonymous"
          checked={isLateCheck}
          onChange={handleIsLateCheck}
        >
          Atrasados
        </Check>

        <Select value={status} onChange={handleStatus}>
          <option value="all">Todos</option>
          {statusList.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>

        <div className="order-control">
          <p>Ordenar por:</p>
          <Select value={order} onChange={handleOrder}>
            <option value="start_at">Data de início</option>
            <option value="finish_at">Data de fim</option>
          </Select>
        </div>
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="pdi-list">
        {!loading &&
          PDIList.map(pdi => (
            <PDIItem
              key={pdi.pk}
              title={pdi.action}
              status={pdi.status}
              isLate={pdi.is_late}
              pillar={pdi.pillar}
              startAt={pdi.start_at}
              finishAt={pdi.finish_at}
              onClick={() => openPDIDetails(pdi.pk)}
            />
          ))}

        {!loading && PDIList.length === 0 && (
          <EmptyList message="Nenhum PDI encontrado." />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMorePDIs}
        disabled={!nextPage || loading || loadingMore}
      />

      {participantId && (
        <Fab>
          <Button
            color="primary"
            className="rounded add-pdi-button"
            onClick={showNewPDIModal}
          >
            <Icon name="add" />
          </Button>
        </Fab>
      )}
    </StyledPDIList>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDIList));
