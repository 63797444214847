import React, { useState } from 'react';

import LimitedTextarea from '~/components/limited-textarea';
import Button from '~/components/button';
import Icon from '~/components/icon';

import StyledOpenAnswer from './styles';

function OpenAnswer({
  title,
  descriptiveAnswer,
  handleDescriptiveAnswer,
  canViewAnswers,
  descriptiveAnswerByManaged,
  disabled
}) {
  const [showAnswerByManaged, setShowAnswerByManaged] = useState(false);

  return (
    <StyledOpenAnswer>
      <div className="descriptive-answer">
        <div className="open-answer-header">
          <h5>{title}</h5>
          {canViewAnswers && (
            <div className="actions">
              {!showAnswerByManaged ? (
                <Button
                  color="white"
                  onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
                  disabled={!descriptiveAnswerByManaged}
                >
                  <Icon name="eye" />
                  Ver resposta
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
                >
                  <Icon name="not-eye" />
                  Ocultar resposta
                </Button>
              )}
            </div>
          )}
        </div>
        {title && showAnswerByManaged && (
          <div className="descriptive-answer-by-managed">
            <h5>Auto avaliação</h5>
            <p>{descriptiveAnswerByManaged}</p>
          </div>
        )}
        <div className="open-answer-input">
          <LimitedTextarea
            limit={1000}
            rows={1}
            value={descriptiveAnswer || ''}
            onChange={handleDescriptiveAnswer}
            placeholder="Insira sua resposta"
            disabled={disabled}
          />
        </div>
      </div>
    </StyledOpenAnswer>
  );
}

export default OpenAnswer;
