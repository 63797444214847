import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './style.scss';
import './ranking.scss';
import useAccount from '~/hooks/use-account';
import { getRankings, getRanking, getRankingAccounts } from '~/api/account';
import Loading from '~/components/loading';
import api from '~/api/api';

const RankingScreen = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [nextAccountUrl, setNextAccountUrl] = useState(null);
  const [rankingFromList, setRankingFromList] = useState(null);

  const account = useAccount();

  const loadMore = useCallback(
    async nextAccountUrl => {
      setLoading(true);
      try {
        const {
          data: { results, next }
        } = await api.get(nextAccountUrl);

        setAccounts([...accounts, ...results]);

        setNextAccountUrl(next);
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    },
    [accounts]
  );

  useEffect(() => {
    // não podemos retornar uma função async/promise para o use effect
    (async function() {
      setLoading(true);
      try {
        const [
          rankingsResponse,
          rankingResponse,
          accountsResponse
        ] = await Promise.all([
          getRankings(),
          getRanking(match.params.id),
          getRankingAccounts(match.params.id)
        ]);

        const rankingFromList = _.get(rankingsResponse, 'data', []).find(
          r => r.ranking.pk.toString() === match.params.id
        );

        setRanking(rankingResponse.data);
        setAccounts(accountsResponse.data.results);
        setNextAccountUrl(accountsResponse.data.next);
        setRankingFromList(rankingFromList);
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (nextAccountUrl && !loading) {
        const { offsetHeight, scrollHeight } = document.body;
        const scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;

        if (offsetHeight + offsetHeight * 0.5 + scrollTop >= scrollHeight) {
          loadMore(nextAccountUrl);
        }
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [nextAccountUrl, loading]);

  return (
    <>
      <div className="account-screen mb20">
        <div className="section-wrapper">
          <div className="title-flex-wrapper">
            <div>
              {ranking && (
                <>
                  <h2>{ranking.title}</h2>
                  <p>
                    {' '}
                    Do dia {moment(ranking.open_at).format(
                      'DD/MM/YYYY'
                    )} até {moment(ranking.close_at).format('DD/MM/YYYY')}
                  </p>
                </>
              )}
            </div>

            {rankingFromList && (
              <div className="you-at-ranking-wrapper">
                <div className="you-at-ranking-box">
                  <span className="you-at-ranking">Você no ranking:</span>
                  <div className="values">
                    {rankingFromList.points.toLocaleString()} pts{' '}
                    <span className="sep">|</span> {rankingFromList.position}º
                    lugar
                  </div>
                </div>

                <img
                  src={_.get(account, 'data.profile.avatar_128x0')}
                  className="rounded img48 ml10"
                  alt={_.get(account, 'data.profile.name')}
                  title={_.get(account, 'data.profile.name')}
                />
              </div>
            )}
          </div>
        </div>

        {ranking && accounts.length > 0 && (
          <div className="section-wrapper">
            <div key={ranking.pk} className="ranking ranking-tabled">
              <header className="ranking-table-header">
                <div className="h-position">Posição</div>

                <div className="h-name">Nome do perfil</div>

                <div className="h-points">Pontuação</div>
              </header>

              <ol className="position-list">
                {accounts.map(acc => {
                  const cls = ['position'];

                  if (acc.position === 1) {
                    cls.push('first-account');
                  }

                  if (acc.account === _.get(account, 'data.id')) {
                    cls.push('my-position');
                  }

                  return (
                    <li key={acc.pk} className={cls.join(' ')}>
                      <div className="pos-name">
                        <div className="badge-count rounded">
                          {acc.position}º
                        </div>

                        <div className="position-avatar-wrapper">
                          <img
                            src={acc.avatar_128x0}
                            alt="Avatar"
                            className="rounded position-avatar"
                          />
                        </div>

                        <span className="position-title">{acc.name}</span>
                      </div>

                      <div>
                        <span className="position-points">
                          {acc.points.toLocaleString()} pontos
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>

            <Loading inline visible={loading} />
          </div>
        )}

        <Loading inline visible={loading && accounts.length === 0} />
      </div>
    </>
  );
};

export default withRouter(RankingScreen);
