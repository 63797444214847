import React from 'react';
import { Container, Row, Col } from '../../grid';
import Logo from '../../logo';
import Copyright from '../../copyright';
import Separator from '../../separator';
import ExternalLink from '../../external-link';
import './style.scss';
import useVersion from '~/hooks/use-version';

const PublicFooter = () => {
  const version = useVersion();

  let style = {};

  if (version === 'desktop') {
    style = {
      padding: '0 59px'
    };
  }

  // <ExternalLink to="https://talkative.media/contato">
  //             Fale Conosto
  //           </ExternalLink>

  return (
    <footer className="footer public-footer">
      <Container style={style}>
        <Separator />
        <Row center>
          <Col md={6}>
            <Logo small vbWidth={70} vbHeight={130} />
            <Copyright />
          </Col>
          <Col
            md={6}
            className={`${version === 'desktop' ? 'text-right' : ''}`}
          >
            <div className="links">
              <ExternalLink to="https://useflow.tech">Sobre Nós</ExternalLink>

              <ExternalLink to="https://d1asjutgsmztwh.cloudfront.net/flow/use-terms">
                Termos de uso e Política de privacidade
              </ExternalLink>
              <ExternalLink to="https://d1asjutgsmztwh.cloudfront.net/flow/cookie-policy">
                Política de cookies
              </ExternalLink>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default PublicFooter;
