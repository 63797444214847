import styled from 'styled-components';

const StyledCustomHeader = styled.header.attrs(() => ({
  className: 'custom-header'
}))`
  width: 100%;
  position: fixed;
  background: var(--white-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 2000;

  display: flex;
  justify-content: center;

  &.has-app-logo {
    background: var(--primary-color);
  }

  .main-content {
    width: 100%;
    max-width: 1070px;
    height: 76px;
    padding-right: 8px;

    display: flex;
    align-items: center;

    .logo-side {
      width: 220px;
      height: 76px;
      padding: 8px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 112px;
      }
    }

    .custom-side {
      flex: 1;
      margin-left: 32px;
    }
  }

  .main-content.expanded {
    max-width: 1200px;

    .logo-side {
      width: max-content;
    }
  }
`;

export default StyledCustomHeader;
