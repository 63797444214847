import React from 'react';
import PostHeader from '~/components/post-header';

const channels = {
  profile: 'Perfil',
  facebook: 'Facebook',
  whatsapp: 'Whatsapp',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  publishing: 'Publicação',
  kaltura: 'Kaltura'
};

const DefaultTaskHeader = props => (
  <PostHeader
    info={channels[props.channel]}
    points={props.wololo_points}
    organization={props.organization}
    meTrail={props.me_trail}
    expire={props.close_at}
    me_create_wololo={props.me_create_wololo}
    channel={props.channel}
  />
);

export default DefaultTaskHeader;
