import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TaskPrize from '~/components/task-prize';
import PropTypes from 'prop-types';
import Icon from '../icon';
import './style.scss';
import RewardBadge from '~/assets/images/Reward-Badge.png';
import StarSmall from '~/assets/images/star-small.svg';
// import ArrowDownRight from '~/assets/images/arrow-down-right.svg';
import Trophy from '~/assets/images/trophy.svg';
import useAccount from '~/hooks/use-account';

const useOrganization = id => {
  const account = useAccount();

  return (_.get(account, 'data.organization_is_active_set') || []).find(
    org => org.id === id
  );
};

const OrganizationImage = ({ id, ...props }) => {
  const organization = useOrganization(id);

  if (!organization) {
    return null;
  }

  return <img src={organization.avatar} {...props} alt={organization.name} />;
};
// const OrganizationPoints = ({ id }) => {
//   const organization = useOrganization(id);

//   if (!organization) {
//     return null;
//   }

//   return organization.points;
// };

const TaskCompletedModal = ({
  location,
  taskId,
  actionPoints,
  childrenCount,
  agilityPoints = 0,
  prizeTitle,
  rankings = [],
  taskType,
  organization: { activeOrganizationId }
  // history
}) => {
  const organization = useOrganization(activeOrganizationId);

  let coin, title;

  const [renderRedirect, setRenderRedirect] = useState(false);

  const handleRedirect = e => {
    setRenderRedirect(true);
  };

  const getSubsequentActions = () => {
    return <Redirect to={{ pathname: '/', search: `?termo=#t${taskId}` }} />;
  };

  if (actionPoints && prizeTitle) {
    title = 'Parabéns, você realizou esta ação.';
    coin = 'prize-star-reward';
  } else if (actionPoints) {
    title = 'Parabéns, você realizou esta ação.';
    coin = 'star';
  } else if (prizeTitle) {
    title = 'Parabéns, você realizou esta ação.';
    coin = 'reward';
  }

  let elems = [];

  if (actionPoints) {
    elems.push(<hr key="points-hr" className="task-modal-separator" />);

    elems.push(
      <React.Fragment key="points">
        <div className="task-modal-row-info">
          <div>
            <p>
              <img
                src={StarSmall}
                alt="star"
                style={{ verticalAlign: 'bottom', marginRight: 5 }}
              />
              Pontos da ação
            </p>
          </div>

          <div>
            <p>{actionPoints.toLocaleString()}</p>
          </div>
        </div>

        {/* {agilityPoints > 0 && (
          <div className="task-modal-row-info">
            <div>
              <p>
                <img
                  src={ArrowDownRight}
                  alt="agilidade"
                  style={{ marginLeft: 10, marginRight: 5 }}
                />
                Agilidade
                <span className="muted">
                  {' '}
                  +{((agilityPoints * 100) / actionPoints).toFixed(0)}%
                </span>
              </p>
            </div>

            <div>
              <p>{agilityPoints.toLocaleString()}</p>
            </div>
          </div>
        )} */}

        <hr className="task-modal-separator" />

        <div className="task-modal-row-info lg">
          <div>
            <Icon name="agility-arrow" />
            <p>Você ganhou:</p>
          </div>

          <div>
            <p>{actionPoints.toLocaleString()}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  elems = elems.concat(
    rankings.map(({ id, points, position }) => (
      <div key={id} className="task-modal-row-info">
        <div>
          <p>
            <img src={Trophy} alt="Troféu" style={{ marginRight: '5px' }} />
            {position}º no Ranking
          </p>
        </div>

        <div>
          <p>{points.toLocaleString()}</p>
        </div>
      </div>
    ))
  );

  if (organization) {
    elems.push(
      <div className="task-modal-row-info" key={activeOrganizationId}>
        <div>
          <p>
            <OrganizationImage
              id={activeOrganizationId}
              style={{
                width: '24px',
                verticalAlign: 'bottom',
                marginRight: 5
              }}
            />
            Total
          </p>
        </div>

        <div>
          <p>{(organization.points + actionPoints).toLocaleString()}</p>
        </div>
      </div>
    );
  }

  if (prizeTitle) {
    elems.push(<hr key="prize-hr" className="task-modal-separator" />);

    elems.push(
      <div className="prize" key="prize">
        <img src={RewardBadge} alt="star" className="reward-icon" />

        <div className="prize-title">{prizeTitle}</div>

        <p>
          Nossa equipe entrará em contato com as instruções para você receber o
          prêmio
        </p>
      </div>
    );
  }

  if (childrenCount > 0) {
    elems.push(
      <React.Fragment key="subsequent-actions">
        <hr className="task-modal-separator" />

        <div className="task-modal-row-info lg unlocked-actions">
          <p>
            Você desbloqueou <span>{childrenCount}</span>{' '}
            {childrenCount > 1 ? 'ações' : 'ação'}
          </p>
          <button type="button" onClick={e => handleRedirect(e)}>
            Ver ações desbloqueadas
          </button>

          {renderRedirect && getSubsequentActions()}
        </div>
      </React.Fragment>
    );
  }

  elems.shift();

  if (!actionPoints) {
    elems.push(
      <div className="prize" key="prize">
        <div className="prize-title">Ação concluída</div>
        <p>Esta ação não é pontuável</p>
      </div>
    );
  }

  if (taskType === 'questionnaire') {
    elems.length = 0;

    elems.push(
      <React.Fragment key="points">
        {actionPoints ? (
          <div className="task-modal-row-info lg">
            <div>
              <Icon name="agility-arrow" />
              <p>Você ganhou:</p>
            </div>

            <div>
              <p>{actionPoints.toLocaleString()}</p>
            </div>
          </div>
        ) : (
          <div className="prize" key="prize">
            <div className="prize-title">Resposta registrada</div>
            <p>
              Nossa equipe irá avaliar as suas respostas. Você pode acompanhar
              os resultados desta ação na seção <i>histórico</i>.
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <>
      <div className="task-complete-modal">
        <div className="message-modal-header">
          <div>
            <TaskPrize coin={coin} />
          </div>
          <h3>{title}</h3>
        </div>
        {/* <p className="point-message">Esta ação não é pontuável</p> */}
        <div className="message-modal-content">{elems}</div>
      </div>
    </>
  );
};

TaskCompletedModal.propTypes = {
  actionPoints: PropTypes.number,
  agilityPoints: PropTypes.number,
  prizeTitle: PropTypes.string,
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      points: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired
    })
  )
};

const mapStateToProps = ({ organization }) => ({
  organization
});

export default connect(mapStateToProps)(TaskCompletedModal);
