import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Routes
import Public from './public';
import Restricted from './restricted';
import Expanded from './expanded';

// Screens
import SignInScreen from '~/screens/sign-in';
import CompleteSignUp from '~/screens/complete-sign-up';
import SignUpScreen from '~/screens/sign-up';
import RecoveryScreen from '~/screens/recovery';
import RecoverySentScreen from '~/screens/recovery-sent';
import PasswordResetScreen from '~/screens/password-reset';
import OnboardScreen from '~/screens/onboard';
import HomeScreen from '~/screens/home';
import PostScreen from '~/screens/post';
import RumorScreen from '~/screens/rumor';
import TaskScreen from '~/screens/task';
import QuestionnaireScreen from '~/screens/questionnaire';
import {
  AccountScreen,
  HistoryScreen,
  ChangePasswordScreen,
  SocialNetworksScreen,
  ConnectionsScreen,
  ConnectionsInviteScreen,
  RankingsScreen,
  RankingScreen,
  TokenLoginScreen,
  WithdrawScreen,
  MobileRedirectScreen
} from '~/screens/account';
import MyProfile from '~/screens/my-profile';
import WololoSuccessScreen from '~/screens/wololo-success';
import WololoContinueScreen from '~/screens/wololo-continue';
import TrailsScreen from '~/screens/trails';
import ForumScreen from '~/screens/forum';
import ContactUsScreen from '~/screens/contact-us';
import ContactChatScreen from '~/screens/contact-chat';
import FAQScreen from '~/screens/faq';
import AppDownload from '~/screens/app-download';
import Downloads from '~/screens/downloads';
import Surveys from '~/screens/surveys';
import SurveyQuestions from '~/screens/survey-questions';
import Page from '~/screens/page';
import Library from '~/screens/library';
import Forms from '~/screens/forms';
import Performances from '~/screens/performances';
import Performance from '~/screens/performance';
import DoPerformance from '~/screens/do-performance';
import PerformanceFeedback from '~/screens/performance-feedback';
import PerformanceAnswers from '~/screens/performance-answers';
import TrainingScreen from '~/screens/training';
import MyCourses from '~/screens/my-courses';
import CoursesHistory from '~/screens/courses-history';
import CourseCategory from '~/screens/course-category';
import CourseDetails from '~/screens/course-details';
import CourseContent from '~/screens/course-content';
import CourseExam from '~/screens/course-exam';
import DoCourse from '~/screens/do-course';
import CourseReactionSurvey from '~/screens/course-reaction-survey';
import PDIList from '~/screens/pdi-list';
import PDIMyTeam from '~/screens/pdi-my-team';
import Metlife from '~/screens/metlife';
import MetlifeDepartments from '~/screens/metlife-departments';
import MetlifeExtracts from '~/screens/metlife-extracts';
import ConsumeFeedLink from '~/screens/consume-feed-link';
import PerformanceGuestValidation from '~/screens/performance-guest-validation';
import PerformanceGuestForm from '~/screens/performance-guest-form';
import PerformanceGuestCompleted from '~/screens/performance-guest-completed';
import PerformancePreviousCycle from '~/screens/performance-previous-cycle';

import UnexpectedError from '~/screens/unexpected-error';
import Error403 from '~/screens/error-403';
import PageNotFound from '~/screens/page-not-found';

// Scroll to top
import ScrollToTop from './scroll-to-top';

const Routes = () => (
  <ScrollToTop>
    <Switch>
      <Public path="/token-login" component={TokenLoginScreen} />
      <Public path="/sign-in" component={SignInScreen} />
      <Public path="/sign-up" component={SignUpScreen} />
      <Public path="/recovery" component={RecoveryScreen} />
      <Public path="/recovery-sent" component={RecoverySentScreen} />
      <Public path="/password-reset" component={PasswordResetScreen} />
      <Public path="/password-reset-confirm" component={PasswordResetScreen} />
      <Public path="/onboard" component={OnboardScreen} />
      <Public inverted path="/complete-sign-up" component={CompleteSignUp} />
      <Public inverted path="/unexpected-error" component={UnexpectedError} />

      <Route path="/error-403" component={Error403} />
      <Route path="/mobile-redirect" component={MobileRedirectScreen} />
      <Route path="/androidapp" component={AppDownload} />
      <Route
        path="/organization/:orgId/performance/:performanceId/competence/:competenceId/performance-guest"
        component={PerformanceGuestValidation}
      />
      <Route
        path="/organization/:orgId/performance/:performanceId/competence/:competenceId/performance-guest-form"
        component={PerformanceGuestForm}
      />
      <Route
        path="/performance-guest-completed"
        component={PerformanceGuestCompleted}
      />

      <Restricted noSidebar exact path="/account" component={AccountScreen} />
      <Restricted
        noSidebar
        exact
        path="/account/change-password"
        component={ChangePasswordScreen}
      />
      <Restricted
        noSidebar
        exact
        path="/account/social-networks"
        component={SocialNetworksScreen}
      />
      <Restricted
        noSidebar
        exact
        path="/connections/invite"
        component={ConnectionsInviteScreen}
      />
      <Restricted
        noSidebar
        exact
        path="/connections"
        component={ConnectionsScreen}
      />
      <Restricted
        noSidebar
        exact
        path="/account/my-profile"
        component={MyProfile}
      />
      <Restricted noSidebar exact path="/rankings" component={RankingsScreen} />
      <Restricted
        noSidebar
        exact
        path="/rankings/:id"
        component={RankingScreen}
      />
      <Restricted exact path="/trails" noSidebar component={TrailsScreen} />
      <Restricted exact path="/forum" noSidebar component={ForumScreen} />
      <Restricted
        exact
        path="/contact-us"
        noSidebar
        component={ContactUsScreen}
      />
      <Restricted
        exact
        path="/contact-chat"
        noSidebar
        component={ContactChatScreen}
      />
      <Restricted exact path="/help" noSidebar component={FAQScreen} />
      <Restricted exact path="/downloads" noSidebar component={Downloads} />
      <Restricted exact path="/forms" noSidebar component={Forms} />
      <Restricted
        exact
        path="/performances"
        noSidebar
        component={Performances}
      />
      <Restricted exact path="/survey" noSidebar component={Surveys} />
      <Restricted
        exact
        path="/survey/questions"
        noSidebar
        component={SurveyQuestions}
      />
      <Restricted exact path="/library" noSidebar component={Library} />
      <Restricted
        exact
        path="/library/:directory"
        noSidebar
        component={Library}
      />
      <Restricted noSidebar exact path="/history" component={HistoryScreen} />
      <Restricted
        path="/wololo-success/:task"
        component={WololoSuccessScreen}
      />
      <Restricted noSidebar exact path="/withdraw" component={WithdrawScreen} />
      <Restricted noMobileMenu path="/balance" component={WithdrawScreen} />
      <Restricted path="/wololo-continue" component={WololoContinueScreen} />
      <Restricted path="/post/:id" component={PostScreen} />
      <Restricted path="/task/:id" redirectTo="/" component={TaskScreen} />
      <Restricted path="/rumor/:id" component={RumorScreen} />
      <Restricted path="/questionnaire" component={QuestionnaireScreen} />
      <Restricted path="/page/:id" component={Page} />
      <Restricted exact path="/metlife" noSidebar component={Metlife} />
      <Restricted
        exact
        path="/metlife/:type/departments"
        noSidebar
        component={MetlifeDepartments}
      />
      <Restricted
        exact
        path="/metlife/:type/departments/:var"
        noSidebar
        component={MetlifeDepartments}
      />
      <Restricted
        exact
        path="/metlife/:type/departments/:var/extracts"
        noSidebar
        component={MetlifeExtracts}
      />
      <Restricted exact path="/" component={HomeScreen} />
      <Restricted exact path="/training" noSidebar component={TrainingScreen} />
      <Restricted exact path="/pdi/my-team" noSidebar component={PDIMyTeam} />
      <Restricted exact path="/pdi" noSidebar component={PDIList} />
      <Restricted
        exact
        path="/pdi/:participantId"
        noSidebar
        component={PDIList}
      />

      <Expanded
        exact
        path="/performance/:id/competence/:page"
        component={Performance}
      />
      <Expanded
        exact
        path="/performance/:id/competence/previous-cycle/:page"
        component={PerformancePreviousCycle}
      />
      <Expanded
        exact
        path="/performance/:id/feedback/:feedback/competence/:page"
        component={PerformanceAnswers}
      />
      <Expanded exact path="/performance/:id" component={Performance} />
      <Expanded
        exact
        path="/org/:orgId/do-performance/:id"
        component={DoPerformance}
      />
      <Expanded
        exact
        path="/performance/:target/feedback/:role"
        component={PerformanceFeedback}
      />
      <Expanded path="/training/my-courses" component={MyCourses} />
      <Expanded path="/training/courses-history" component={CoursesHistory} />
      <Expanded path="/training/category/:id" component={CourseCategory} />
      <Expanded path="/training/course/:id" exact component={CourseDetails} />
      <Expanded
        path="/training/course/:id/step/:id"
        component={CourseContent}
      />
      <Expanded path="/training/course/:id/exam" component={CourseExam} />
      <Expanded path="/org/:orgId/do-course/:id" component={DoCourse} />
      <Expanded
        path="/training/course/:id/reaction-survey"
        component={CourseReactionSurvey}
      />
      <Expanded path="/org/:orgId/task/:taskId" component={ConsumeFeedLink} />
      <Expanded path="/org/:orgId/post/:postId" component={ConsumeFeedLink} />

      <Route component={PageNotFound} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
