import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Poll from '../../poll';
import { showNewPoints } from '~/services/tasks';
import { addWololo } from '~/services/tasks';
import { REACT_APP_SITE } from '~/settings';
import { capitalizeWord } from '~/utils';
// import useSite from '~/hooks/use-site';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: '',
      loading: false,
      poll_no_percent: null,
      poll_yes_percent: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(review) {
    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });

      addWololo(this.props.id, { message: review.toString() })
        .then(response => {
          console.log('Response = ', response);
          showNewPoints(this.props.pointsToShow);
          this.props.onFinish();
        })
        .catch(() => {
          this.setState({ loading: false });
          alert('Erro');
        });
    }
  }

  render() {
    return (
      <Poll
        onChange={this.handleSubmit}
        pollState={{
          poll_yes_percent: this.state.poll_yes_percent,
          poll_no_percent: this.state.poll_no_percent
        }}
      >
        Como você avalia o{' '}
        {capitalizeWord(
          REACT_APP_SITE === 'geracao_s' ? 'Geração SESI SENAI' : REACT_APP_SITE
        )}
        ?
      </Poll>
    );
  }
}

Review.defaultProps = {
  onFinish: () => {}
};

Review.propTypes = {
  onFinish: PropTypes.func
};

export default Review;
