import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useDispatch } from 'redux-react-hook';
import renderHTML from 'react-render-html';

import {
  getCompetence,
  saveCompetence,
  sendCompetence
} from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';
import {
  closeDefaultModal,
  openDefaultModal
} from '~/store/ducks/default-modal';

import { deepClone } from '~/utils';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SuspendedMenu from '~/components/suspended-menu';
import CollaboratorCard from '~/components/collaborator-card';
import AlertBar from '~/components/alert-bar';
import PageControl from '~/components/page-control';
import PerformanceQuestion from '~/components/performance-question';
import OpenAnswer from '~/components/open-answer';
import Fab from '~/components/fab';
import CustomLoading from '~/components/custom-loading';
import PendingModificationModal from '~/components/pending-modification-modal';
import ConfirmModal from '~/components/confirm-modal';

import StyledPerformance from './styles';

let changeControl = {};

function Performance({ organization, showMessage, location, history }) {
  const { activeOrganizationId } = organization;
  const performanceId = location.pathname
    .split('/competence/')[0]
    .replace('/performance/', '');
  const competencePage = location.pathname.split('/competence/')[1];
  const firstPage = competencePage ? parseInt(competencePage, 10) : 1;

  const dispatch = useDispatch();

  const [competenceConfig, setCompetenceConfig] = useState(null);
  const [evaluatedConfig, setEvaluatedConfig] = useState(null);
  const [performanceConfig, setPerformanceConfig] = useState(null);
  const [questionsConfig, setQuestionsConfig] = useState([]);

  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(true);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const handleCompetenceDescriptiveAnswer = ({ target }) => {
    setCompetenceConfig(competence => ({
      ...competence,
      open_answer: target.value
    }));
  };

  const handlePerformanceDescriptiveAnswer = ({ target }) => {
    setPerformanceConfig(performance => ({
      ...performance,
      open_answer: target.value
    }));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePendingChanges = () => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal
          onAction={() => history.push('/performances')}
        />
      )
    );
  };

  const handleBack = () => {
    const currentState = {
      competence: { ...competenceConfig },
      performance: { ...performanceConfig },
      questions: [...questionsConfig]
    };

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges();
      return;
    }

    history.push('/performances');
  };

  const getSaveData = () => {
    const questions = questionsConfig.map(question => ({
      pk: question.pk,
      answer_option: question.answer_option,
      open_answer: question.open_answer || ''
    }));

    return {
      open_answer: competenceConfig.open_answer || '',
      performance: {
        open_answer: performanceConfig.open_answer || ''
      },
      questions
    };
  };

  const handleSave = () => {
    setSaveLoading(true);
    const saveData = getSaveData();

    saveCompetence(activeOrganizationId, performanceId, currentPage, saveData)
      .then(() => {
        showMessage('Avaliação salva!', 'success', 1000);

        changeControl = deepClone({
          competence: { ...competenceConfig },
          performance: { ...performanceConfig },
          questions: [...questionsConfig]
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao salvar a avaliação.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title="Enviar avaliação?"
          description="Tem certeza que deseja enviar esta avaliação?"
          confirmButtonText="Enviar"
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = () => {
    setSendLoading(true);
    const saveData = getSaveData();
    dispatch(closeDefaultModal());

    saveCompetence(activeOrganizationId, performanceId, currentPage, saveData)
      .then(() => {
        changeControl = deepClone({
          competence: { ...competenceConfig },
          performance: { ...performanceConfig },
          questions: [...questionsConfig]
        });
        sendCompetence(activeOrganizationId, performanceId, currentPage)
          .then(() => {
            showMessage(
              'Obrigado! A sua resposta à avaliação foi registrada.',
              'success'
            );
            history.push('/performances');
          })
          .catch(error => {
            if (error.response.status === 400) {
              showMessage(error.response.data.errors[0], 'danger');
              return;
            }

            history.push('/unexpected-error');
          })
          .finally(() => {
            setSendLoading(false);
          });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao enviar a avaliação.', 'danger', 3000);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const changePage = page => {
    setQuestionsLoading(true);
    const saveData = getSaveData();

    saveCompetence(activeOrganizationId, performanceId, currentPage, saveData)
      .then(() => {
        getCompetence(activeOrganizationId, performanceId, page, false)
          .then(response => {
            setCurrentPage(page);
            const { evaluated, questions } = response.data;

            delete response.data['evaluated'];
            delete response.data['performance'];
            delete response.data['questions'];

            setCompetenceConfig(response.data);
            setEvaluatedConfig(evaluated);
            setQuestionsConfig(questions);

            changeControl = deepClone({
              competence: { ...response.data },
              performance: { ...performanceConfig },
              questions: [...questions]
            });

            window.history.pushState(
              'competence',
              'competence',
              `${location.pathname.split('/competence')[0]}/competence/${page}`
            );
          })
          .catch(error => {
            history.push('/unexpected-error');
          })
          .finally(() => {
            setQuestionsLoading(false);
          });
      })
      .catch(() => {
        history.push('/unexpected-error');
      });
  };

  useEffect(() => {
    getCompetence(activeOrganizationId, performanceId, firstPage)
      .then(response => {
        const { evaluated, performance, questions } = response.data;

        delete response.data['evaluated'];
        delete response.data['performance'];
        delete response.data['questions'];

        setCompetenceConfig(response.data);
        setEvaluatedConfig(evaluated);
        setPerformanceConfig(performance);
        setQuestionsConfig(questions);

        changeControl = deepClone({
          competence: { ...response.data },
          performance: { ...performance },
          questions: [...questions]
        });
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage('Esta avaliação não está disponível.', 'danger', 3000);
          history.push('/performances');
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledPerformance>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side">
            <Button
              color="primary"
              className="outline"
              disabled={loading || saveLoading || sendLoading}
              onClick={handleSave}
            >
              {!saveLoading && 'Salvar'}
              {saveLoading && (
                <CustomLoading type="spin" height={16} width={16} fluid />
              )}
            </Button>
            <Button
              color="primary"
              disabled={loading || saveLoading || sendLoading}
              onClick={confirmSend}
            >
              {!sendLoading && 'Enviar'}
              {sendLoading && (
                <CustomLoading
                  type="spin"
                  height={16}
                  width={16}
                  fluid
                  color="#FFFFFF"
                />
              )}
            </Button>

            <SuspendedMenu>
              <Button
                color="primary"
                className="outline"
                disabled={loading || saveLoading || sendLoading}
                onClick={handleSave}
              >
                {!saveLoading && 'Salvar'}
                {saveLoading && (
                  <CustomLoading type="spin" height={16} width={16} fluid />
                )}
              </Button>
              <Button
                color="primary"
                className="outline"
                disabled={loading || saveLoading || sendLoading}
                onClick={confirmSend}
              >
                {!sendLoading && 'Enviar'}
                {sendLoading && (
                  <CustomLoading
                    type="spin"
                    height={16}
                    width={16}
                    fluid
                    color="#FFFFFF"
                  />
                )}
              </Button>
            </SuspendedMenu>
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="performance-header">
              <p className="finish-date">
                Envio disponível até{' '}
                {moment(competenceConfig.finish_at).format('DD/MM/YYYY')}
              </p>

              <CollaboratorCard
                name={evaluatedConfig.name}
                avatar={evaluatedConfig.avatar_128x0}
                department={evaluatedConfig.department}
                profile={evaluatedConfig.profile}
                instructions={performanceConfig.description}
                hasPreviousCycleAnswer={
                  competenceConfig.has_previous_cycle_answer
                }
                onClickPreviousCycleAnswerUrl={() =>
                  history.push(
                    `/performance/${performanceId}/competence/previous-cycle/1`
                  )
                }
              />

              {competenceConfig.is_manager &&
                competenceConfig.type === 'auto+managers' && (
                  <AlertBar
                    icon="triangle-alert"
                    title="Atenção!"
                    theme="warning"
                  >
                    As perguntas podem estar escritas de forma direcionada à
                    leitura da pessoa avaliada. Responda as questões a seguir
                    tomando como base sua avaliação do desempenho do liderado
                    cujo nome está apresentado no cabeçalho deste formulário.
                  </AlertBar>
                )}
            </div>

            <PageControl
              pageTitle={competenceConfig.title}
              pageDescription={renderHTML(competenceConfig.description)}
              counterLabel={`${currentPage}/${competenceConfig.count}`}
              onPrevious={() =>
                changePage(
                  currentPage > 1 ? currentPage - 1 : competenceConfig.count
                )
              }
              onNext={() =>
                changePage(
                  currentPage !== competenceConfig.count
                    ? currentPage + 1
                    : firstPage
                )
              }
              disabled={
                questionsLoading ||
                saveLoading ||
                sendLoading ||
                competenceConfig.count === 1
              }
            />

            <p class="mandatory-filling-message">
              Todas as questões são obrigatórias
            </p>

            <div className="performance-questions">
              {!questionsLoading &&
                questionsConfig.map((question, index) => (
                  <PerformanceQuestion
                    key={question.pk}
                    questionNumber={index + 1}
                    statement={question.title}
                    modelImage={performanceConfig.answer_model}
                    options={question.options}
                    answer={question.answer_option}
                    descriptiveAnswer={question.open_answer}
                    openAnswerQuestion={question.open_answer_question}
                    answerByManaged={question.managed_answer_option}
                    descriptiveAnswerByManaged={question.managed_open_answer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    questions={questionsConfig}
                    setQuestions={setQuestionsConfig}
                    disabled={saveLoading || sendLoading}
                  />
                ))}

              {!questionsLoading &&
                competenceConfig &&
                competenceConfig.open_answer_question && (
                  <OpenAnswer
                    title={competenceConfig.open_answer_question}
                    descriptiveAnswer={competenceConfig.open_answer}
                    handleDescriptiveAnswer={handleCompetenceDescriptiveAnswer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    descriptiveAnswerByManaged={
                      competenceConfig.managed_open_answer
                    }
                    disabled={saveLoading || sendLoading}
                  />
                )}

              {!questionsLoading &&
                performanceConfig &&
                performanceConfig.open_answer_question &&
                currentPage === competenceConfig.count && (
                  <OpenAnswer
                    title={performanceConfig.open_answer_question}
                    descriptiveAnswer={performanceConfig.open_answer}
                    handleDescriptiveAnswer={handlePerformanceDescriptiveAnswer}
                    canViewAnswers={competenceConfig.can_view_answer}
                    descriptiveAnswerByManaged={
                      performanceConfig.managed_open_answer
                    }
                    disabled={saveLoading || sendLoading}
                  />
                )}

              {questionsLoading && (
                <CustomLoading type="spin" height={56} width={56} fluid />
              )}
            </div>

            {/* <div className="performance-list">
              {!loading && performances.map(performance => <h1>Opa</h1>)}

              {!loading && performances.length === 0 && (
                <EmptyList message="Nenhuma avaliação encontrada." />
              )}
            </div> */}

            {/* {loadingMore && (
              <CustomLoading
                type="spin"
                height={36}
                width={36}
                fluid
              />
            )} */}

            <Fab>
              <Button
                color="dark"
                className="rounded go-to-top"
                onClick={scrollToTop}
              >
                <Icon name="arrow-up" />
              </Button>
            </Fab>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledPerformance>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Performance));
