import React from 'react';
// import { getAccount, getRankings } from '~/api/account';
import { getAccount } from '~/api/account';
import { getInfo, postWololo } from '~/api/tasks';
import TaskCompletedModal from '~/components/task-completed-modal';
import store from '~/store';
import { registerAccount } from '~/store/ducks/account';
import { openMessageModal } from '~/store/ducks/message-modal';

// const getRankingData = data =>
//   data
//     .filter(r => r.ranking.status !== 'closed')
//     .map(({ ranking: { pk }, points, position }) => ({
//       id: pk,
//       points,
//       position
//     }));

const addWololo = (
  id,
  dataWololo = {},
  showPoints = true,
  onClose = null,
  showError = true
) => {
  return new Promise((resolve, reject) => {
    const config = {};

    if (dataWololo instanceof FormData) {
      config.headers = {
        'content-type': 'multipart/form-data'
      };
    }

    // Promise with Ranking

    // Promise.all([getInfo(id), getRankings()])
    //   .then(([{ data: { wololo_reward } }, rankingResponse]) => {
    //     postWololo(id, dataWololo, config).then(({ data: taskData }) => {
    //       if (showPoints) {
    //         store.dispatch(
    //           openMessageModal(
    //             <TaskCompletedModal
    //               prizeTitle={wololo_reward}
    //               actionPoints={taskData.points}
    //               agilityPoints={taskData.bonus}
    //               rankings={getRankingData(rankingResponse.data)}
    //             />
    //           )
    //         );
    //       }

    //       getAccount()
    //         .then(({ data }) => {
    //           store.dispatch(registerAccount(data));
    //           resolve(taskData);
    //         })
    //         .catch(reject);
    //     });
    //   })
    //   .catch(e => {
    //     if (showError) {
    //       alert('Error!');
    //     }
    //     reject(e);
    //   });

    Promise.all([getInfo(id)])
      .then(
        ([
          {
            data: { wololo_reward, children_count, type, wololo_points }
          }
        ]) => {
          postWololo(id, dataWololo, config)
            .then(({ data: taskData }) => {
              if (showPoints) {
                store.dispatch(
                  openMessageModal(
                    <TaskCompletedModal
                      taskId={id}
                      prizeTitle={wololo_reward}
                      childrenCount={children_count}
                      actionPoints={taskData.points}
                      agilityPoints={taskData.bonus}
                      taskType={type}
                      // rankings={getRankingData(rankingResponse.data)}
                    />
                  )
                );
              }

              getAccount()
                .then(({ data }) => {
                  store.dispatch(registerAccount(data));
                  resolve(taskData);
                })
                .catch(reject);
            })
            .catch(({ response }) => {
              reject(response);
            });
        }
      )
      .catch(e => {
        if (showError) {
          alert('Error!');
        }
        reject(e);
      });
  });
};

const onlyShowPoints = async (taskId, onClose) => {
  const { data } = await getInfo(taskId);
  // const { data: rankingData } = await getRankings();

  store.dispatch(
    openMessageModal(
      <TaskCompletedModal
        actionPoints={data.points}
        // rankings={getRankingData(rankingData)}
      />,
      onClose
    )
  );
};

const showNewPoints = async (newPoints, onClose) => {
  // const { data: rankingData } = await getRankings();

  store.dispatch(
    openMessageModal(
      <TaskCompletedModal
        actionPoints={newPoints}
        // rankings={getRankingData(rankingData)}
      />,
      onClose
    )
  );
};

export { addWololo, showNewPoints, onlyShowPoints };
