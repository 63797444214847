import React from 'react';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
// import StoreLinks from '~/components/store-links';
import CheckIcon from '~/assets/images/check-icon.svg';

const RecoverySentScreen = () => (
  <PublicWrapper>
    <PublicHeader>
      <Card className="public-card text-center">
        <div>
          <img src={CheckIcon} alt="Enviado" />
        </div>
        <h3>Solicitação enviada</h3>
        <p>
          Verifique a caixa de entrada do seu email e siga as instruções para
          criar uma nova senha.
        </p>
        <Button fill large to="/sign-in" color="primary">
          Ok
        </Button>
        <Separator />
        <p>
          <span>Não recebeu o email? Verifique a caixa de spams ou </span>
          <Link to="/recovery">reenvie a solicitação</Link>.
        </p>
        <Separator />
        <p>
          <Link to="/sign-in">
            <span>Voltar para o login</span>
            <Icon name="arrow-right" marginLeftSmall />
          </Link>
        </p>
      </Card>
    </PublicHeader>
    <div className="main">
      <PublicBanner>
        <span>Seja bem-vindo!</span>
      </PublicBanner>

      <div className="hide-on-mobile" style={{ marginBottom: 175 }} />
    </div>
    <PublicFooter />
  </PublicWrapper>
);

export default RecoverySentScreen;
