import React, { useState } from 'react';

import Button from '~/components/button';
import Icon from '~/components/icon';
import LimitedTextarea from '~/components/limited-textarea';

import startIcon from '~/assets/images/star-model.svg';
import pointIcon from '~/assets/images/point-model.svg';

import StyledPerformanceQuestion from './styles';

function PerformanceQuestion({
  questionNumber,
  statement,
  modelImage,
  options,
  answer,
  descriptiveAnswer,
  openAnswerQuestion,
  answerByManaged,
  descriptiveAnswerByManaged,
  canViewAnswers,
  questions,
  setQuestions,
  disabled
}) {
  const models = {
    radio: pointIcon,
    star: startIcon
  };

  const [showAnswerByManaged, setShowAnswerByManaged] = useState(false);

  const handleSelect = answer => {
    setQuestions(() => {
      const currentQuestions = [...questions];
      currentQuestions[questionNumber - 1].answer_option = answer;
      return currentQuestions;
    });
  };

  const handleDescriptiveAnswer = ({ target }) => {
    setQuestions(() => {
      const currentQuestions = [...questions];
      currentQuestions[questionNumber - 1].open_answer = target.value;
      return currentQuestions;
    });
  };

  return (
    <StyledPerformanceQuestion>
      <div className="question-header">
        <div className="info">
          <div className="question-number">{questionNumber}</div>
          <p className="statement">{statement}</p>
        </div>
        {canViewAnswers && (
          <div className="actions">
            {!showAnswerByManaged ? (
              <Button
                color="white"
                onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
                disabled={!answerByManaged}
              >
                <Icon name="eye" />
                Ver respostas
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => setShowAnswerByManaged(!showAnswerByManaged)}
              >
                <Icon name="not-eye" />
                Ocultar respostas
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answer === option.pk ? ' selected' : ''}
              ${
                answerByManaged === option.pk && showAnswerByManaged
                  ? ' answer-by-managed'
                  : ''
              }
            `}
            onClick={() => handleSelect(option.pk)}
            disabled={disabled}
          >
            <img src={models[modelImage]} alt="model" />
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
      {descriptiveAnswerByManaged && showAnswerByManaged && (
        <div className="descriptive-answer-by-managed">
          <h5>{openAnswerQuestion} • Auto avaliação</h5>
          <p>{descriptiveAnswerByManaged}</p>
        </div>
      )}
      {openAnswerQuestion && (
        <div className="descriptive-answer">
          <h5>{openAnswerQuestion}</h5>
          <LimitedTextarea
            limit={1000}
            rows={1}
            value={descriptiveAnswer || ''}
            onChange={handleDescriptiveAnswer}
            placeholder="Insira sua resposta"
            disabled={disabled}
          />
        </div>
      )}
    </StyledPerformanceQuestion>
  );
}

export default PerformanceQuestion;
