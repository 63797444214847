import React from 'react';
import { useDispatch } from 'redux-react-hook';
import renderHTML from 'react-render-html';

import { openDefaultModal } from '~/store/ducks/default-modal';

import Button from '~/components/button';

import StyledCollaboratorCard from './styles';

function CollaboratorCard({
  name,
  avatar,
  department,
  profile,
  manager,
  instructions,
  hasPreviousCycleAnswer = false,
  onClickPreviousCycleAnswerUrl = null,
  children
}) {
  const dispatch = useDispatch();

  //
  const showInstructions = () => {
    dispatch(
      openDefaultModal(
        <div className="instruction-modal" style={{ marginBottom: '16px' }}>
          <h3 style={{ fontSize: '24px', marginBottom: '28px' }}>
            Instruções da avaliação
          </h3>
          {renderHTML(instructions)}
        </div>
      )
    );
  };

  return (
    <StyledCollaboratorCard>
      <div className="segmentation">
        <p>
          {department} {!manager ? ` • ${profile}` : ''}
        </p>
        <div className="segmentation-buttons">
          {hasPreviousCycleAnswer && (
            <Button
              color="primary"
              className="outline"
              onClick={onClickPreviousCycleAnswerUrl}
            >
              Ver última avaliação
            </Button>
          )}
          {instructions && (
            <Button
              color="primary"
              className="outline"
              onClick={showInstructions}
            >
              Ver instruções
            </Button>
          )}
        </div>
      </div>
      <div className="profile-info">
        <div className="profiles">
          <div className="managed">
            {avatar && (
              <div className="avatar">
                <img src={avatar} alt="Avatar" />
              </div>
            )}
            <div className="text">
              <p>Avaliação de</p>
              <h3>{name}</h3>
              {manager && <p>{profile}</p>}
            </div>
          </div>

          {manager && (
            <div className="manager">
              {manager.avatar_128x0 && (
                <div className="avatar">
                  <img src={manager.avatar_128x0} alt="Avatar" />
                </div>
              )}
              <div className="text">
                <p>Avaliado por</p>
                <h3>{manager.name}</h3>
                {manager && <p>{manager.profile}</p>}
              </div>
            </div>
          )}
        </div>

        <div className="custom-content">{children}</div>
      </div>
    </StyledCollaboratorCard>
  );
}

export default CollaboratorCard;
