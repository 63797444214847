import React from 'react';
import { connect } from 'react-redux';

import { getConfig } from '~/utils';

import Logo from '~/components/logo';

import StyledCustomHeader from './styles';

function CustomHeader({
  expanded,
  account,
  organization,
  organizationConfig,
  children
}) {
  const config =
    organizationConfig ||
    getConfig(
      account.data.organization_is_active_set,
      organization.activeOrganizationId
    );

  return (
    <StyledCustomHeader className={`${config.appLogo ? 'has-app-logo' : ''}`}>
      <div className={`main-content ${expanded ? 'expanded' : ''}`}>
        <div className="logo-side">
          <Logo customLogo={config.appLogo} />
        </div>
        <div className="custom-side">{children}</div>
      </div>
    </StyledCustomHeader>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(CustomHeader);
