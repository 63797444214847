import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { validateGuestEmail } from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';

import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import Separator from '~/components/separator';
import { Form, Input } from '~/components/form';
import Button from '~/components/button';
import Icon from '~/components/icon';

import StyledPerformanceGuestValidation from './styles';

function PerformanceGuestValidation({ location, history, showMessage }) {
  const organizationId = location.pathname
    .split('/performance/')[0]
    .replace('/organization/', '');
  const performanceId = location.pathname
    .split('/competence/')[0]
    .split('/performance/')[1];
  const competencePage = location.pathname
    .split('/competence/')[1]
    .replace('/performance-guest/', '');
  const token = location.search.replace('?token=', '');

  const [email, setEmail] = useState('');

  const handleSend = () => {
    validateGuestEmail(organizationId, performanceId, competencePage, token, {
      email
    })
      .then(response => {
        const formUrl = `/organization/${organizationId}/performance/${performanceId}/competence/${competencePage}/performance-guest-form/?token=${response.data.token}`;
        history.push(formUrl);
      })
      .catch(error => {
        if (error.response.data.status === 'sended') {
          history.push('/performance-guest-completed');
          return;
        }

        showMessage(error.response.data.error, 'danger', 5000);
      });
  };

  return (
    <StyledPerformanceGuestValidation>
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <h3>Avaliação de desempenho</h3>
            <Separator>
              Insira seu e-mail para iniciar a avaliação de seus liderados
            </Separator>
            <Form disabled={false} onSubmit={() => {}}>
              <Input
                name="email"
                type="text"
                placeholder="Digite aqui o e-mail em que você recebeu o link"
                autoCorrect="off"
                autoFocus
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <br />
              <Button fill large color="primary" onClick={handleSend}>
                <Icon name="check" marginRight />
                <span>Responder avaliação</span>
              </Button>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner></PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledPerformanceGuestValidation>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PerformanceGuestValidation));
