import styled from 'styled-components';

export const StyledOpenAnswer = styled.div.attrs(() => ({
  className: 'open-answer'
}))`
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 32px;

  & > .descriptive-answer {
    border-bottom: 1px solid var(--border-color);

    .open-answer-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;
      padding: 32px 42px;
      padding-bottom: 0px;

      .actions {
        .button {
          width: 168px;
        }

        .button.white {
          color: var(--secondary-text-color);
          background: var(--background-color);

          :hover {
            background: var(--border-color);
          }
        }

        .button:disabled:hover {
          background: var(--background-color);
        }
      }
    }

    & > .descriptive-answer-by-managed {
      padding: 32px 42px;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 32px;

      h5 {
        font-size: 16px;
        color: var(--text-color);
        font-weight: normal;
      }

      p {
        color: var(--secondary-text-color);
        line-height: 18px;
      }
    }

    h5 {
      font-size: 16px;
      color: var(--text-color);
      font-weight: normal;
    }

    .input {
      padding: 12px 16px;
    }

    .open-answer-input {
      padding: 32px 42px;
      padding-top: 0px;
    }
  }

  @media (max-width: 720px) {
    & > .descriptive-answer {
      .open-answer-header {
        padding: 16px;
        padding-bottom: 0px;
      }

      .descriptive-answer-by-managed {
        padding: 16px;
        margin-bottom: 16px;
      }

      .open-answer-input {
        padding: 16px;
        padding-top: 0px;
      }
    }
  }
`;

export default StyledOpenAnswer;
