import React, { useState } from 'react';

import { StyledLimitedTextarea } from './styles';

function LimitedTextarea({
  limit = 256,
  rows,
  value,
  onChange = () => {},
  placeholder,
  className,
  disabled
}) {
  const [count, setCount] = useState(value ? value.length : 0);

  const handleChange = event => {
    if (event.target.value.length <= limit) {
      setCount(event.target.value.length);
      onChange(event);
    }
  };

  return (
    <StyledLimitedTextarea className={className}>
      <textarea
        value={value}
        onChange={handleChange}
        cols="30"
        rows={rows || '8'}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div className="counter">
        <span>{count}</span>/<span>{limit}</span>
      </div>
    </StyledLimitedTextarea>
  );
}

export default LimitedTextarea;
