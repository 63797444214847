import { addWololo } from '~/services/tasks';

const runTask = (id, url, isSubtask = false, onFinish) => {
  return new Promise(resolve => {
    const win = window.open(url);

    const checkIsOpen = () => {
      if (win.opener && !win.opener.closed) {
        setTimeout(checkIsOpen, 500);
      } else {
        if (!isSubtask) {
          addWololo(id);
        } else {
          onFinish(id);
        }

        resolve();
      }
    };

    checkIsOpen();
  });
};

export default runTask;
