import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useDispatch } from 'redux-react-hook';

import { tokenRefresh } from '~/api/auth';
import { getAccount, getAlerts } from '~/api/account';

import store from '~/store';
import { showMessage } from '~/store/ducks/messageBar';
import { registerAccount, unregisterAccount } from '~/store/ducks/account';
import { setOrganization } from '~/store/ducks/organization';
import {
  openGenericModal,
  closeGenericModal
} from '~/store/ducks/generic-modal';

import { logoff, getToken, isAuth, upadateTokenKey } from '~/services/auth';

import {
  findActiveOrganization,
  getConfig,
  storeOrganizationId
} from '~/utils';

import useSite from '~/hooks/use-site';
import usePages from '~/hooks/use-pages';
import useLinks from '~/hooks/use-links';
import useVersion from '~/hooks/use-version';

import { Container } from '~/components/grid';
import NavMenu from '~/components/nav-menu';
import DefaultModal from '~/components/default-modal';
import PostModal from '~/components/post-modal';
import MessageModal from '~/components/message-modal';
import MediaModal from '~/components/media-modal';
import { SearchAside } from '~/components/search-area';
import StoreLinks from '~/components/store-links';
import MobileNav from '~/components/mobile-nav';
import Notifications from '~/components/profile-task/tasks/notifications';
import Modal from '~/components/modal';
import OrganizationAlert from '~/components/organization-alert';
import CustomHeader from '~/components/custom-header';
import Footer from '~/components/footer';
import BonusModal from '~/components/bonus-modal';

import './style.scss';

const makeNavOptions = (
  avatar,
  history,
  unregisterAccount,
  links,
  dispatch,
  organizationIsActiveSet,
  pages,
  activeOrganizationId
) => {
  const config = getConfig(organizationIsActiveSet, activeOrganizationId);

  if (!config) {
    return [];
  }

  let finalLinks = [
    {
      url: '/account',
      name: 'Minha Conta',
      image: avatar
    },
    {
      url: '/',
      name: 'Home',
      icon: 'home',
      exact: true
    },
    {
      url: '/trails',
      name: 'Trilhas',
      icon: 'trails'
    }
  ];

  if (config.moduleRanking) {
    finalLinks = finalLinks.concat({
      url: '/rankings',
      name: 'Rankings',
      icon: 'rankings'
    });
  }

  if (config.modulePDI) {
    finalLinks = finalLinks.concat({
      url: '/pdi',
      name: config.moduleSettings.pdi.name || 'Desenvolvimento',
      icon: 'home'
    });
  }

  if (config.moduleTraining) {
    finalLinks = finalLinks.concat({
      url: '/training',
      name: config.moduleSettings.training.name || 'Treinamentos',
      icon: 'training'
    });
  }

  finalLinks = finalLinks.concat([
    {
      url: '/forum',
      name: 'Fórum',
      icon: 'home'
    },
    {
      url: '/withdraw',
      name: 'Câmbio',
      icon: 'home'
    }
  ]);

  if (config.moduleDownloads) {
    finalLinks = finalLinks.concat({
      url: '/downloads',
      name: 'Downloads',
      icon: 'download'
    });
  }

  if (config.moduleSurvey || config.modulePerformance) {
    finalLinks = finalLinks.concat({
      url: '/forms',
      name: 'Pesquisas & Avaliações',
      icon: 'forms'
    });
  }

  if (config.moduleLibrary) {
    finalLinks = finalLinks.concat({
      url: '/library',
      name: 'Biblioteca',
      icon: 'home'
    });
  }

  if (config.moduleMetlife) {
    finalLinks = finalLinks.concat({
      url: '/metlife',
      name: 'Metlife',
      icon: 'metlife'
    });
  }

  finalLinks = finalLinks.concat([
    {
      url: '/history',
      name: 'Histórico',
      icon: 'history'
    }
  ]);

  finalLinks = finalLinks.concat(
    pages.map(page => {
      const menuOption = {
        name: page.title,
        icon: 'link',
        url: `/page/${page.id}`,
        onClick: () => {
          window.open(page.url);
        }
      };

      if (page.url) {
        delete menuOption['url'];
      } else {
        delete menuOption['onClick'];
      }

      return menuOption;
    })
  );

  finalLinks = finalLinks.concat([
    {
      name: 'Políticas',
      icon: 'link',
      submenuId: 'lhh',
      suboptions: [
        {
          name: 'Termos de uso e Política de privacidade',
          icon: 'link',
          onClick: () => {
            window.open('https://d1asjutgsmztwh.cloudfront.net/flow/use-terms');
          }
        },
        {
          name: 'Política de Cookies',
          icon: 'link',
          onClick: () => {
            window.open(
              'https://d1asjutgsmztwh.cloudfront.net/flow/cookie-policy'
            );
          }
        }
      ]
    }
  ]);

  if (config.moduleContact) {
    finalLinks = finalLinks.concat([
      {
        url: '/contact-us',
        name: 'Fale Conosco',
        icon: 'feedback'
      }
    ]);
  }

  finalLinks = finalLinks.concat([
    {
      url: '/help',
      name: 'Dúvidas Frequentes',
      icon: 'help'
    },

    {
      className: 'exit',
      icon: 'logout',
      name: 'Sair',
      onClick: () => {
        unregisterAccount();
        logoff();
        history.push('/sign-in');
      }
    }
  ]);

  return finalLinks;
};

const RestrictedLayout = props => {
  const {
    account,
    organization: { activeOrganizationId },
    unregisterAccount,
    defaultModal,
    messageModal,
    mediaModal,
    genericModal,
    noSidebar,
    noMobileMenu,
    history
  } = props;

  const dispatch = useDispatch();
  // const alerts = useAlerts();
  const { loginMinutes, showStoreLinks } = useSite();
  const version = useVersion();

  const [activeOrganization, setActiveOrganization] = useState(null);

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  if (
    account.data &&
    account.data.is_reseted_password &&
    props.match.path !== '/account/change-password'
  ) {
    history.push('/account/change-password');
  }

  useEffect(() => {
    if (isAuth(loginMinutes) && !props.account.loaded) {
      getAccount()
        .then(({ data }) => {
          props.registerAccount(data);

          const newActiveOrganization = findActiveOrganization(
            data.organization_is_active_set,
            activeOrganizationId
          );

          setActiveOrganization(newActiveOrganization);
          store.dispatch(setOrganization(newActiveOrganization.id));

          const token = getToken();
          tokenRefresh({
            organization: newActiveOrganization.id,
            token: token
          })
            .then(response => {
              upadateTokenKey(response.data.token);
              setOrganizationId(newActiveOrganization.id);
            })
            .catch(() => {
              history.push('/unexpected-error');
            });

          // console.log('Alerts', alerts, data);
          // if (alerts && alerts.length > 0) {
          //   console.log(alerts);
          // } else {
          //   console.log('Caiu no else', alerts);
          // }

          getAlerts().then(response => {
            const alerts = response.data;
            if (alerts.length > 0) {
              console.log('Alerta = ', alerts[0]);
              store.dispatch(
                openGenericModal(
                  <Modal
                    onClose={() => {
                      store.dispatch(closeGenericModal());
                    }}
                  >
                    <OrganizationAlert data={alerts[0]} />
                  </Modal>
                )
              );
            }
          });

          if (data.access_bonus) {
            setTimeout(() => {
              dispatch(
                openGenericModal(
                  <BonusModal
                    onClose={() => {
                      dispatch(closeGenericModal());
                    }}
                    daysAccess={data.access_bonus.days}
                    points={data.access_bonus.daily_points}
                  />
                )
              );
            }, 1000);
          }
        })
        .catch(e => {
          if (
            ['authentication_failed', 'not_authenticated'].includes(
              _.get(e, 'response.data.code')
            )
          ) {
            unregisterAccount();
            logoff();
            history.push('/sign-in');
            return;
          }

          history.push('/unexpected-error');
        });
    }
  }, []);

  const links = useLinks();
  let config = {};

  if (account.data) {
    config = getConfig(
      account.data.organization_is_active_set,
      activeOrganizationId
    );
  }

  const pages = usePages(
    account.data ? activeOrganizationId : null,
    config.modulePages
  );

  const navOptions = useMemo(() => {
    if (account.loaded) {
      return makeNavOptions(
        account.data ? account.data.profile.avatar_128x0 : '',
        props.history,
        unregisterAccount,
        links.data,
        dispatch,
        account.data.organization_is_active_set,
        pages || [],
        activeOrganizationId
      );
    } else {
      return [];
    }
  }, [account, links, pages]);

  return (
    <>
      {version === 'mobile' && !noMobileMenu && (
        <MobileNav
          organization={account.data ? activeOrganization : null}
          organizationIsActiveSet={
            account.data ? account.data.organization_is_active_set : null
          }
        />
      )}
      <Notifications />
      <div className="restricted-wrapper">
        {account.loaded && (
          <>
            {version === 'desktop' && <CustomHeader />}

            <div className="content">
              <Container style={{ maxWidth: '1100px' }}>
                {version === 'desktop' && (
                  <aside className="aside nav-menu-aside default-scroll">
                    <NavMenu
                      options={navOptions}
                      name={account.data.profile.name}
                      organizations={account.data.organization_is_active_set}
                    />
                    {showStoreLinks && (
                      <StoreLinks>
                        <p>
                          Através do nosso app você também pode interagir com
                          nossa plataforma.
                        </p>
                      </StoreLinks>
                    )}
                  </aside>
                )}

                <main className="restricted-main">{props.children}</main>

                {version === 'desktop' && !noSidebar && <SearchAside />}
              </Container>
            </div>

            <Footer />
          </>
        )}
        <PostModal />

        <DefaultModal
          isOpen={defaultModal.open}
          onClose={defaultModal.onClose}
          maxWidth={defaultModal.maxWidth}
        >
          {defaultModal.content}
        </DefaultModal>

        <MessageModal isOpen={messageModal.open} onClose={messageModal.onClose}>
          {messageModal.content}
        </MessageModal>

        {mediaModal.open && (
          <MediaModal onClose={mediaModal.onClose}>
            {mediaModal.content}
          </MediaModal>
        )}

        {/* used in modal, do not remove */}
        {genericModal.content ? genericModal.content : null}
        <div id="modal-root" />
      </div>
    </>
  );
};

const mapStateToProps = ({
  account,
  organization,
  defaultModal,
  messageModal,
  mediaModal,
  genericModal
}) => ({
  account,
  organization,
  defaultModal,
  messageModal,
  mediaModal,
  genericModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerAccount,
      unregisterAccount,
      showMessage
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RestrictedLayout)
);
