import api from './api';

export const getCyclesTarget = (
  orgId,
  filters = {},
  page,
  cancelTokenSource
) => {
  return api.get(`/organization/${orgId}/performance/`, {
    params: {
      ordering: filters.ordering,
      name: filters.name,
      status: filters.status,
      page,
      id: filters.id
    },
    cancelToken: cancelTokenSource ? cancelTokenSource.token : null
  });
};

export const getManagedProfiles = (orgId, targetId, filters = {}, page) =>
  api.get(`/organization/${orgId}/performance/${targetId}/managed/`, {
    params: {
      ordering: filters.ordering,
      search: filters.name,
      status: filters.status,
      page
    }
  });

export const getCompetence = (orgId, performanceId, page, showHeader = true) =>
  api.get(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/${
      !showHeader ? '?show-header=false' : ''
    }`
  );

export const saveCompetence = (orgId, performanceId, page, data) =>
  api.put(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/`,
    data
  );

export const sendCompetence = (orgId, performanceId, page) =>
  api.post(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/`
  );

export const getPreviousCycleCompetence = (
  orgId,
  performanceId,
  page,
  showHeader = true
) =>
  api.get(
    `/organization/${orgId}/performance/${performanceId}/competence/previous-cycle/${page}/${
      !showHeader ? '?show-header=false' : ''
    }`
  );

export const getPerformanceRedirect = (orgId, cycleId) =>
  api.get(`/organization/${orgId}/performance/${cycleId}/redirect/`);

export const getCompetencePreview = (orgId, performanceId, feedbackId, page) =>
  api.get(
    `/organization/${orgId}/performance/${performanceId}/feedback/${feedbackId}/competence/${page}`
  );

export const getFeedbackTargets = (orgId, targetId, filters = {}, page) =>
  api.get(`/organization/${orgId}/performance/${targetId}/feedback/target/`, {
    params: {
      ordering: filters.ordering,
      search: filters.name,
      status: filters.status,
      page
    }
  });

export const getFeedback = (orgId, targetId, feedbackId) =>
  api.get(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/answer/`
  );

export const sendFeedback = (orgId, targetId, feedbackId) =>
  api.post(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/answer/`
  );

export const updateFeedback = (orgId, targetId, feedbackId, data) =>
  api.put(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/answer/`,
    data
  );

export const createFeedbackPDI = (orgId, targetId, feedbackId, data) =>
  api.post(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/`,
    data
  );

export const getPDIs = (orgId, targetId, feedbackId, page) =>
  api.get(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/`,
    {
      params: {
        page
      }
    }
  );

export const createPDI = (orgId, targetId, feedbackId, data) =>
  api.post(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/`,
    data
  );

export const showPDI = (orgId, targetId, feedbackId, PDIId) =>
  api.get(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/${PDIId}`
  );

export const updatePDI = (orgId, targetId, feedbackId, PDIId, data) =>
  api.put(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/${PDIId}`,
    data
  );

export const deletePDI = (orgId, targetId, feedbackId, PDIId) =>
  api.delete(
    `/organization/${orgId}/performance/${targetId}/feedback/${feedbackId}/pdi/${PDIId}`
  );

export const validateGuestEmail = (orgId, performanceId, page, token, data) =>
  api.post(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/guest/?token=${token}`,
    data
  );

export const getGuestCompetence = (
  orgId,
  performanceId,
  page,
  token,
  showHeader = true
) =>
  api.get(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/guest/?token=${token}${
      !showHeader ? '&show-header=false' : ''
    }`
  );

export const saveGuestCompetence = (orgId, performanceId, page, token, data) =>
  api.put(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/guest/?token=${token}`,
    data
  );

export const sendGuestCompetence = (orgId, performanceId, page, token) =>
  api.post(
    `/organization/${orgId}/performance/${performanceId}/competence/${page}/guest/?token=${token}`
  );

export default getCyclesTarget;
