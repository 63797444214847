import React, { useState, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import renderHTML from 'react-render-html';
import axios from 'axios';

import { REACT_APP_SITE } from '~/settings';

import { getTrainingProfile, getCourses } from '~/api/training';

import { openDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import CustomLoading from '~/components/custom-loading';
import PageHeader from '~/components/page-header';
import SearchInput from '~/components/search-input';
// import Card from '~/components/card';
import Button from '~/components/button';
import SectionList from '~/components/section-list';
import CourseCategoryCard from '~/components/course-category-card';
import CourseCard from '~/components/course-card';
// import Icon from '~/components/icon';
import ViewAllCategoriesModal from '~/components/view-all-categories-modal';
import ViewAllCoursesModal from '~/components/view-all-courses-modal';
import InfiniteScroll from '~/components/infinite-scroll';
import CourseItem from '~/components/course-item';
import EmptyList from '~/components/empty-list';
import Icon from '~/components/icon';

import { StyledTraining } from './styles';

function Training({ organization, history, showMessage }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchLoading, setSearchLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [search, setSearch] = useState('');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [cancelTokenSourceContent, setCancelTokenSourceContent] = useState(
    null
  );
  const [
    cancelTokenSourceCourseList,
    setCancelTokenSourceCourseList
  ] = useState(null);

  const [pageTitle, setPageTitle] = useState('Treinamentos');
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [pageDescription, setPageDescription] = useState(null);

  const [myCoursesTitle, setMyCoursesTitle] = useState('Meus cursos');
  const [myCourses, setMyCourses] = useState([]);
  const [myCoursesDescriptionCount, setMyCoursesDescriptionCount] = useState(0);
  const [myCourseDescriptionName, setMyCourseDescriptionName] = useState(
    'cursos'
  );

  const [categoriesTitle, setCategoriesTitle] = useState('Categorias');
  const [categories, setCategories] = useState([]);

  const [othersCoursesTitle, setOthersCoursesTitle] = useState('Sugestões');
  const [othersCourses, setOthersCourses] = useState([]);

  const [courses, setCourses] = useState([]);

  const viewAllCategories = () => {
    dispatch(
      openDefaultModal(
        <ViewAllCategoriesModal
          title={categoriesTitle}
          orgId={activeOrganizationId}
        />
      )
    );
  };

  const viewAllCourses = () => {
    dispatch(
      openDefaultModal(
        <ViewAllCoursesModal
          title={othersCoursesTitle}
          orgId={activeOrganizationId}
          onlyCanSubscribe={1}
        />
      )
    );
  };

  const showDescription = () => {
    dispatch(openDefaultModal(renderHTML(pageDescription)));
  };

  const getCourseList = (filters, page) => {
    setCurrentPage(firstPage);

    if (cancelTokenSourceCourseList) {
      cancelTokenSourceCourseList.cancel('canceled');
    }
    const newCancelTokenSourceCourseList = axios.CancelToken.source();
    setCancelTokenSourceCourseList(newCancelTokenSourceCourseList);

    getCourses(
      activeOrganizationId,
      filters,
      page,
      newCancelTokenSourceCourseList
    )
      .then(response => {
        setCourses(() => response.data.results);
        setNextPage(response.data.next);
        setSearchLoading(false);
      })
      .catch(error => {
        if (error.message !== 'canceled') {
          showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
          setSearchLoading(false);
        }
      });
  };

  const loadMoreCourses = () => {
    setLoadingMore(true);

    const filters = {
      name: search
    };

    if (cancelTokenSourceCourseList) {
      cancelTokenSourceCourseList.cancel('canceled');
    }
    const newCancelTokenSourceCourseList = axios.CancelToken.source();
    setCancelTokenSourceCourseList(newCancelTokenSourceCourseList);

    getCourses(
      activeOrganizationId,
      filters,
      currentPage + 1,
      newCancelTokenSourceCourseList
    )
      .then(response => {
        setCourses(lastCourseList => [
          ...lastCourseList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
        setLoadingMore(false);
      })
      .catch(error => {
        if (error.message !== 'canceled') {
          showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
          setLoadingMore(false);
        }
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;
    const filters = {
      name: value
    };

    if (filters.name === '') {
      setSearchLoading(true);
      setCourses([]);
      setSearch(value);
      setCurrentTimeOut(null);
      setLoadingMore(false);
      setNextPage(null);
      setCurrentPage(firstPage);
      return;
    }

    const timeOut = setTimeout(() => {
      getCourseList(filters);
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const loadContent = () => {
    setLoading(true);

    if (cancelTokenSourceContent) {
      cancelTokenSourceContent.cancel('canceled');
    }
    const newCancelTokenSourceContent = axios.CancelToken.source();
    setCancelTokenSourceContent(newCancelTokenSourceContent);

    getTrainingProfile(activeOrganizationId, newCancelTokenSourceContent)
      .then(response => {
        const {
          title,
          short_description,
          description,
          courses,
          categories,
          others_courses
        } = response.data;
        setPageTitle(title);
        setPageSubtitle(short_description);
        setPageDescription(description);

        setMyCoursesTitle(courses.title);
        setMyCourses(courses.objects);

        setMyCourseDescriptionName(
          courses.count === 1 ? courses.course_name : courses.course_name_plural
        );
        setMyCoursesDescriptionCount(courses.count);

        setCategoriesTitle(categories.title);
        setCategories(categories.objects);

        setOthersCoursesTitle(others_courses.title);
        setOthersCourses(others_courses.objects);
        setLoading(false);
      })
      .catch(error => {
        if (error.message === 'canceled') {
          return;
        }

        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage('Ocorreu um erro ao carregar a conteúdo.', 'danger', 3000);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <StyledTraining>
      {!loading && (
        <div className="action-header">
          <Button
            color="primary"
            className="outline"
            onClick={() => history.push('/training/courses-history')}
          >
            <Icon name="history" />
            Meu histórico
          </Button>
        </div>
      )}

      {!loading && (
        <PageHeader>
          <div className="text-info">
            <h1>{pageTitle}</h1>
            <SearchInput
              value={search}
              onChange={handleSearch}
              placeholder="Busque por nome"
            />
          </div>
          <p>{renderHTML(pageSubtitle.replace(/(?:\r\n|\r|\n)/g, '<br>'))}</p>
          {pageDescription && (
            <Button color="white" className="link" onClick={showDescription}>
              Saiba mais
            </Button>
          )}
        </PageHeader>
      )}

      {!loading && search === '' && categories.length > 0 && (
        <SectionList
          title={categoriesTitle}
          viewAllLabel="Ver mais"
          viewAllAction={viewAllCategories}
          className="training-section-list"
        >
          {categories.map(category => (
            <CourseCategoryCard
              key={category.pk}
              title={category.title}
              categoryImage={category.cover}
              showTitle={category.show_title}
              onClick={() => history.push(`/training/category/${category.pk}`)}
            />
          ))}
        </SectionList>
      )}

      {!loading && search === '' && myCourses.length > 0 && (
        <SectionList
          title={myCoursesTitle}
          description={`${myCoursesDescriptionCount} ${myCourseDescriptionName.toLowerCase()} a realizar`}
          viewAllLabel="Ver mais"
          viewAllAction={() => history.push('/training/my-courses')}
          className="training-section-list"
        >
          {myCourses.map(course => (
            <CourseCard
              key={course.pk}
              title={course.title}
              showCourseTitle={course.show_course_title}
              cover={course.cover}
              startAt={course.start_at}
              finishAt={course.finish_at}
              onClick={() => {
                // Usado para verificar a url de origem ao clicar no botão voltar na página de detalhes do curso
                localStorage.setItem(
                  `@${REACT_APP_SITE}/previousUrl`,
                  history.location.pathname
                );
                history.push(`/training/course/${course.pk}`);
              }}
            />
          ))}
        </SectionList>
      )}

      {!loading && search === '' && othersCourses.length > 0 && (
        <SectionList
          title={othersCoursesTitle}
          viewAllLabel="Ver mais"
          viewAllAction={viewAllCourses}
          className="training-section-list"
        >
          {othersCourses.map(course => (
            <CourseCard
              key={course.pk}
              title={course.title}
              showCourseTitle={course.show_course_title}
              cover={course.cover}
              startAt={course.start_at}
              finishAt={course.finish_at}
              onClick={() => {
                // Usado para verificar a url de origem ao clicar no botão voltar na página de detalhes do curso
                localStorage.setItem(
                  `@${REACT_APP_SITE}/previousUrl`,
                  history.location.pathname
                );
                history.push(`/training/course/${course.pk}`);
              }}
            />
          ))}
        </SectionList>
      )}

      {search !== '' && (
        <div className="courses-found">
          {courses.map(course => (
            <CourseItem
              key={course.pk}
              courseId={course.pk}
              title={course.title}
              image={course.cover}
              startAt={course.start_at}
              finishAt={course.finish_at}
              stepCount={course.steps_count}
              stepLabel={
                course.steps_count !== 1
                  ? course.step_name_plural
                  : course.step_name
              }
              trailCount={course.trails_count}
              trailLabel={
                course.trails_count !== 1
                  ? course.trail_name_plural
                  : course.trail_name
              }
            />
          ))}
        </div>
      )}

      {search !== '' && !searchLoading && courses.length === 0 && (
        <EmptyList message="Nenhuma item encontrado." />
      )}

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      {loading && (
        <CustomLoading
          type="spin"
          height={56}
          width={56}
          fluid
          className="screen-loading"
        />
      )}

      {search !== '' && searchLoading && (
        <CustomLoading
          type="spin"
          height={56}
          width={56}
          fluid
          className="screen-loading"
        />
      )}

      <InfiniteScroll
        fetchMore={loadMoreCourses}
        disabled={!nextPage || loading || loadingMore} // Adicionar verificação do search !== de ''
      />
    </StyledTraining>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Training));
