import React, { PureComponent } from 'react';
import Card from '~/components/card';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { Form, Field } from 'react-final-form';
import { required } from '~/components/form/validators';
import { Input } from '~/components/form';
import Button from '~/components/button';
import Icon from '~/components/icon';
import { addWololo } from '~/services/tasks';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { WololoRewardLine } from '~/components/rewards';

const messageRequired = required('Você deve informar um valor');

class MessageTask extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: '',
      commentCounter: this.props.comment_counter
    };

    this.incrementCommentCounter = this.incrementCommentCounter.bind(this);
    this.answer = this.answer.bind(this);
  }

  incrementCommentCounter() {
    this.setState({ commentCounter: this.state.commentCounter + 1 });
  }

  setMessage = message => {
    const { id, onFinish } = this.props;

    this.setState({ message });
    onFinish(id, { message });
  };

  answer = message => {
    const { id } = this.props;

    this.setState({ loading: true });

    addWololo(id, {
      message
    })
      .then(() => this.props.onFinish())
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { isSubtask } = this.props;

    return (
      <Card className={`${isSubtask ? 'subtask' : ''}`}>
        {!isSubtask && <TaskHeader {...this.props} icon_type="post" />}
        <TaskBody {...this.props} />
        {!this.props.me_create_wololo && (
          <Form
            onSubmit={({ message }) => this.answer(message)}
            initialValues={{
              message: ''
            }}
            render={({ handleSubmit }) => (
              <div className="check-task-wrapper">
                <Field
                  name="message"
                  textarea
                  validate={messageRequired}
                  render={({ meta: { touched, error }, input, ...props }) => (
                    <>
                      {isSubtask ? (
                        <Input
                          {...props}
                          {...input}
                          invalid={
                            touched && error && this.state.message === ''
                          }
                          value={this.state.message}
                          onChange={e => this.setMessage(e.target.value)}
                        />
                      ) : (
                        <Input
                          {...props}
                          {...input}
                          invalid={touched && error}
                        />
                      )}
                      {touched && error && this.state.message === '' ? (
                        <p className="text-center danger-message">{error}</p>
                      ) : null}
                    </>
                  )}
                />

                {!isSubtask && (
                  <div className="text-center">
                    <Button
                      onClick={handleSubmit}
                      color={this.props.channel}
                      title="Enviar Resposta"
                      disabled={this.state.loading}
                    >
                      <Icon name={this.props.channel} marginRight />
                      <span>Enviar Resposta</span>
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        )}

        {this.props.can_comment ? (
          <CommentBoxAndButtons
            {...this.props}
            commentCounter={this.state.commentCounter}
            incrementCommentCounter={this.incrementCommentCounter}
            childCommentsProps="taskcomment_set"
            CommentBoxComponent={TaskCommentBox}
          />
        ) : null}

        {this.props.wololo_reward && <WololoRewardLine {...this.props} />}
      </Card>
    );
  }
}

export default MessageTask;
