export const APP_ID = '628921817880793';

let endpoint;

if (process.env.REACT_APP_API) {
  endpoint = process.env.REACT_APP_API;
} else {
  endpoint =
    process.env.NODE_ENV === 'development'
      ? 'https://edukative.dev.talkative.media/v1'
      : 'https://edukative.talkative.media/v1';
}

export const REACT_APP_SITE = process.env.REACT_APP_SITE || 'edukative';

export const TOKEN_KEY = 'ktoken';
export const SKIP_TUTORIAL_KEY = 'skpt';

export const BASE_ENDPOINT = endpoint;

const orgIds = {
  flow: {
    dev: 1,
    prod: 11
  },
  lhh: {
    dev: 1,
    prod: 21
  }
};

export const ORGANIZATION_ID =
  process.env.NODE_ENV === 'development'
    ? orgIds[REACT_APP_SITE].dev
    : orgIds[REACT_APP_SITE].prod;

export const CRISP_WEBSITE_ID = '6db8e69f-140e-4fa5-89dd-43f267ee3f3f';

export const kalturaPlayerConfig = {
  embedUrl: process.env.REACT_APP_KPLAYER_EMBED_URL,
  partnerId: process.env.REACT_APP_KPLAYER_PARTNER_ID,
  uiConfId: process.env.REACT_APP_KPLAYER_UI_CONF_ID
};
