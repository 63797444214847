export const Types = {
  OPEN: 'post-modal/OPEN',
  CLOSE: 'post-modal/CLOSE'
};

const defaultState = {
  open: false,
  id: null,
  save: false,
  content: '',
  isSubtask: false,
  subtaskAction: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.OPEN:
      return {
        open: true,
        id: action.id,
        save: action.save,
        content: action.content,
        isSubtask: action.isSubtask,
        subtaskAction: action.subtaskAction
      };
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const openPostModal = (content, id, save, isSubtask, subtaskAction) => ({
  type: Types.OPEN,
  content,
  id,
  save,
  isSubtask,
  subtaskAction
});

export const closePostModal = () => ({
  type: Types.CLOSE
});
