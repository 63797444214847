import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const AccountMenu = ({ account }) => {
  const { t } = useTranslation();

  const { has_custom_fields } = account.data.profile;

  const itens = [
    {
      label: t('account.account-menu.items.item-1'),
      url: '/account'
    }
    // {
    //   label: t('account.account-menu.items.item-3'),
    //   url: '/account/social-networks'
    // }
  ];

  if (has_custom_fields) {
    itens.push({
      label: 'Meu perfil',
      url: '/account/my-profile'
    });
  }

  itens.push({
    label: t('account.account-menu.items.item-2'),
    url: '/account/change-password'
  });

  return (
    <div className="account-menu">
      {itens.map(item => (
        <NavLink key={item.url} to={item.url} exact activeClassName="active">
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps)(AccountMenu);
