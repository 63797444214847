import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { connect } from 'react-redux';
import PostComment from '../post-comment';
// import PostHeader from '../post-header';
// import PostImage from '../post-image';
import { ForumPostCommentBox } from '~/components/comments';
import ProfileInfo from '../profile-info';
// import Tags from '../tags';
import Separator from '../separator';
import Button from '../button';
import Icon from '../icon';
import SharePost from '../share-post';
// import Poll from '../poll';
import { getComments, postComment } from '~/api/forum';
import LikeButtons from '~/components/like-buttons';
import useComments from '~/hooks/use-comments';
import './style.scss';

const CommentCount = ({ type, id }) => {
  const { count } = useComments(type, id);

  return count;
};

class ViewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: '',
      loaded: false,
      showShare: false,
      newComment: '',
      showComment: false,
      comments: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showShare = this.showShare.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    // this.changePoll = this.changePoll.bind(this);
  }

  componentDidMount() {
    if (this.props.data.can_comment) {
      getComments(this.props.data.id)
        .then(({ data }) => {
          const { next, results } = data;

          const parseDate = date => `${moment(date).fromNow(true)} atrás`;

          const parsedResult = results.map(comment => ({
            ...comment,
            created_at: parseDate(comment.created_at),
            accountcomment_set: comment.accountcomment_set.map(sub => ({
              ...sub,
              created_at: parseDate(sub.created_at)
            }))
          }));

          this.setState({
            loaded: true,
            comments: parsedResult,
            next
          });
        })
        .catch(e => {
          this.setState({ loaded: true });
          console.error(e);
        });
    }
  }

  handleChange({ target }) {
    this.setState({ newComment: target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    postComment(this.props.data.id, this.state.newComment)
      .then(({ data }) => {
        const { id } = this.props.data.account.data;
        const {
          name,
          avatar,
          avatar_128x0
        } = this.props.data.account.data.profile;

        data.created_at = `${moment(data.created_at).fromNow(true)} atrás`;

        data.owner = {
          id,
          name,
          avatar,
          avatar_128x0
        };

        this.setState(({ comments }) => ({
          comments: [data, ...comments]
        }));

        this.hideOptions();
      })
      .catch(e => {
        console.error(e);
        alert('Erro');
      });
  }

  showShare() {
    this.setState({
      showShare: true,
      showComment: false
    });
  }

  hideOptions() {
    this.setState({
      showShare: false,
      newComment: '',
      showComment: false
    });
  }

  // changePoll(value) {
  //   postPoll(this.props.data.id, value)
  //     .then(() => {
  //       alert('Resposta registrada!');
  //     })
  //     .catch(e => {
  //       console.error(e);
  //       alert('Erro');
  //     });
  // }

  setShowComments = () => {
    this.setState({ showComment: !this.showComment });
  };

  render() {
    const { owner } = this.props.data;

    return (
      <div className="view-post">
        <ProfileInfo
          name={owner.name}
          image={owner.avatar_128x0}
          info=""
          rightImage={null}
        />
        <div className="card-body">
          <h3 className="text-center">{this.props.data.title}</h3>
          {this.props.data.description && (
            <p className="text-center">{this.props.data.description}</p>
          )}
          {this.props.data.updated_at && (
            <div>
              <small>
                <i>Atualizado em </i>
                <i>
                  {moment(this.props.data.updated_at).format(
                    'D [de] MMM [de] YYYY [às] HH:mm'
                  )}
                </i>
              </small>
            </div>
          )}
          <Separator />
          {this.props.data.content && (
            <div className="post-html">
              {renderHTML(this.props.data.content)}
            </div>
          )}
          {/* {this.props.data.tags && <Tags>{this.props.data.tags}</Tags>} */}
        </div>
        {/* {this.props.data.have_poll && (
          <Poll onChange={this.changePoll}>{this.props.data.poll}</Poll>
        )} */}

        <div>
          {this.props.data.can_comment && (
            // todo renderizar o contador do redux
            <PostComment
              link
              className="inline-block"
              onClick={this.setShowComments}
            >
              <CommentCount type="forumpost" id={this.props.data.id} />
            </PostComment>
          )}

          {this.props.data.can_like && (
            <LikeButtons
              id={this.props.data.id}
              type="forumpost"
              // allowLikeOnce
            />
          )}
        </div>

        {this.props.data.can_comment && this.state.showComment && (
          <ForumPostCommentBox id={this.props.data.id} />
        )}

        <footer style={{ position: 'relative' }}>
          <div className="card-footer-options">
            {this.state.showShare && (
              <SharePost
                post={this.props.data.id}
                link={this.props.data.link}
              />
            )}
          </div>
          <div className="card-footer-buttons">
            {this.state.showShare && (
              <>
                <Button outline color="primary" onClick={this.hideOptions}>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      lineHeight: 1,
                      marginRight: 7
                    }}
                  >
                    &times;
                  </span>
                  <span>Cancelar</span>
                </Button>
              </>
            )}
            {!this.state.showShare && this.props.data.can_share && (
              <Button color="primary" onClick={this.showShare}>
                <Icon name="share" marginRight />
                Compartilhar
              </Button>
            )}
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(ViewPost);
