import React, { PureComponent, useState, useCallback } from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '~/components/icon';
import useVersion from '~/hooks/use-version';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'redux-react-hook';
import { InputFinalForm, SelectInputFinalForm } from '~/components/form';
import {
  maskValidator,
  requiredValueValidator,
  required,
  brDateMaskedValidator,
  composeValidators,
  cpfValidator as cpfFormValidator
} from '~/components/form/validators';
import './style.scss';
import { updateProfile } from '~/api/account';
import {
  unregisterAccount,
  updateProfile as dispatchUpdateProfile
} from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';
import { removeRequest } from '~/api/account';
import { states } from '~/components/profile-task/tasks/addr_cep';
import { logoff } from '~/services/auth';
import AccountMenu from './account-menu';
import Loading from '~/components/loading';
import { CepField } from '~/components/fields/CepField';
import useSite from '~/hooks/use-site';

const genderValidator = required('Você deve informar seu gênero');

const nascValidator = composeValidators(
  brDateMaskedValidator,
  required('Você deve informar uma data')
);

const cpfValidator = composeValidators(
  maskValidator,
  cpfFormValidator,
  required('Você deve informar um cpf')
);

const selectStates = states.map(s => [s.uf, s.name]);

const genderOptions = [
  ['male', 'Masculino'],
  ['female', 'Feminino'],
  ['other', 'Outro']
];

class ProfileImage extends PureComponent {
  state = {
    previewUrl: null
  };

  loadPreview = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ previewUrl: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ previewUrl: null });
    }
  };

  render() {
    const {
      input: { value, onChange }
    } = this.props;

    const { previewUrl } = this.state;

    let url = previewUrl || value.default;

    return (
      <>
        <img src={url} alt="imagem" className="rounded profile-img" />

        <button className="outline button secondary ml10 relative">
          <input
            className="file-button-hidden"
            accept="image/*"
            type="file"
            onChange={e => {
              this.loadPreview(e.target.files[0]);
              onChange({
                ...value,
                file: e.target.files[0]
              });
            }}
          />
          <Icon name="camera" /> Escolher
        </button>
      </>
    );
  }
}

const AccountScreen = ({
  initialValues,
  onSubmit,
  onCancel,
  loading,
  history
}) => {
  const version = useVersion();
  const { name: siteName } = useSite();

  // precisei adicionar suporte a exclusão de conta sem ser via popup
  // dai decidi colocar aqui via hook a gerencia do estado por que fica mais fail
  //
  // mantive no outro component (enhanced) porque é um class based, a ideia é
  // migrar pra facilitar a manutenção

  const [showExcludeAccount, setShowExcludeAccount] = useState(false);
  const [loadingExcludeAccount, setLoadingExcludeAccount] = useState(false);

  const onShowExcludeAccountForm = useCallback(
    () => setShowExcludeAccount(true),
    []
  );

  const dispatch = useDispatch();

  const excludeAccount = useCallback(
    password => {
      if (loadingExcludeAccount) {
        return;
      }

      setLoadingExcludeAccount(true);

      removeRequest(password)
        .then(() => {
          logoff();
          dispatch(unregisterAccount());
          dispatch(showMessage('Conta removida com sucesso', 'danger'));
          history.push('/sign-in');
        })
        .catch(e => {
          setLoadingExcludeAccount(false);

          alert(
            'Houve um error ao tentar remover. Verifique se a sua senha está correta.'
          );
        });
    },
    [loadingExcludeAccount]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        values: { addr_cep, email },
        form: { change }
      }) => (
        <form onSubmit={handleSubmit}>
          <Loading visible={loading} />

          <div className="account-screen">
            <AccountMenu />
            <div className="section-wrapper">
              <h2>Meus Dados</h2>
              <p>
                Mantenha os dados cadastrais e pessoais da sua conta sempre
                atualizados.
              </p>
            </div>

            <div className="form-section section-wrapper">
              <div>
                <h3 className="form-section-title">Dados Cadastrais</h3>
              </div>

              <div>
                <div className="input-group">
                  <div>
                    <label>Imagem do perfil</label>

                    <Field name="image" component={ProfileImage} />
                  </div>
                </div>

                <div className="input-group">
                  <div>
                    <label>Nome completo</label>
                    <Field
                      name="name"
                      component={InputFinalForm}
                      validate={requiredValueValidator}
                      disabled={siteName === 'USEFLOW'}
                    />
                  </div>
                </div>

                {siteName !== 'USEFLOW' && (
                  <div className="input-group">
                    <div>
                      <label>Email</label>
                      <Field
                        name="email"
                        type="email"
                        component={InputFinalForm}
                        validate={requiredValueValidator}
                        disabled={siteName === 'USEFLOW'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {siteName !== 'USEFLOW' && (
              <div className="form-section section-wrapper">
                <div>
                  <h3 className="form-section-title">Dados Pessoais</h3>
                </div>

                <div>
                  <div className="input-group">
                    <div>
                      <label>Data de Nasc.</label>
                      <Field
                        name="born_at"
                        parse={null}
                        mask={[
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        component={InputFinalForm}
                        validate={nascValidator}
                      />
                    </div>

                    <div>
                      <label>Gênero</label>
                      <Field
                        name="gender"
                        component={SelectInputFinalForm}
                        options={genderOptions}
                        validate={genderValidator}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div>
                      <label>Telefone</label>
                      <Field
                        name="phone"
                        parse={null}
                        mask={[
                          '(',
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        component={InputFinalForm}
                        validate={maskValidator}
                      />
                    </div>

                    <div>
                      <label>Cpf</label>
                      <Field
                        name="cpf"
                        parse={null}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/
                        ]}
                        component={InputFinalForm}
                        validate={cpfValidator}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div>
                      <label>Cep</label>
                      <CepField
                        value={addr_cep}
                        name="addr_cep"
                        onResolve={({ street, neighborhood, city, state }) => {
                          change('addr_street', street || '');
                          change('addr_district', neighborhood || '');
                          change('addr_city', city || '');
                          change('addr_state', state || '');
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div
                      style={
                        version === 'desktop'
                          ? { width: '180px', flex: 'initial' }
                          : null
                      }
                    >
                      <label>Estado</label>
                      <Field
                        name="addr_state"
                        component={SelectInputFinalForm}
                        options={selectStates}
                        validate={requiredValueValidator}
                      />
                    </div>

                    <div>
                      <label>Cidade</label>
                      <Field
                        name="addr_city"
                        component={InputFinalForm}
                        parse={null}
                        options={genderOptions}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div>
                      <label>Endereço</label>
                      <Field
                        name="addr_street"
                        parse={null}
                        component={InputFinalForm}
                      />
                    </div>

                    <div
                      style={
                        version === 'desktop'
                          ? { width: '100px', flex: 'initial' }
                          : null
                      }
                    >
                      <label>Número</label>
                      <Field
                        name="addr_number"
                        parse={null}
                        component={InputFinalForm}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div>
                      <label>Complemento</label>
                      <Field
                        name="addr_complement"
                        parse={null}
                        component={InputFinalForm}
                      />
                    </div>

                    <div>
                      <label>Bairro</label>
                      <Field
                        name="addr_district"
                        parse={null}
                        component={InputFinalForm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!showExcludeAccount && (
              // <div class="section-wrapper">
              //   <div className="input-group delete-account">
              //     <div>

              //     </div>
              //   </div>
              // </div>

              <div class="section-wrapper delete-account">
                <button
                  className="button danger link"
                  type="button"
                  onClick={onShowExcludeAccountForm}
                >
                  Excluir minha conta
                </button>
              </div>
            )}

            {showExcludeAccount && (
              <Form
                onSubmit={({ password }) => excludeAccount(password)}
                render={({ handleSubmit }) => (
                  <div class="section-wrapper">
                    <div className="input-group delete-account-form">
                      <div>
                        <label>Digite sua senha</label>
                        <Field
                          type="password"
                          name="password"
                          placeholder="Para cancelar sua conta, é necessário informar sua senha"
                          component={InputFinalForm}
                          validate={requiredValueValidator}
                        />
                      </div>
                      <div>
                        <button
                          className="button danger sub-form-button"
                          type="button"
                          onClick={handleSubmit}
                          disabled={loadingExcludeAccount}
                        >
                          Confirmar exclusão
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              />
            )}

            {siteName !== 'USEFLOW' && (
              <div className="form-section section-wrapper">
                <div className="mobile-buttons-full actions">
                  <button
                    onClick={onCancel}
                    type="button"
                    className="outline button secondary"
                  >
                    <Icon name="cancel-circle" /> Cancelar alterações
                  </button>

                  <button className="button secondary ml10">
                    <Icon name="floppy-disk" /> Salvar
                  </button>
                </div>
              </div>
            )}

            <div className="form-section section-wrapper">
              <div className="mobile-buttons-full actions">
                {/* <button
                  onClick={onCancel}
                  type="button"
                  className="outline button secondary"
                >
                  <Icon name="cancel-circle" /> Cancelar alterações
                </button> */}

                <button className="button secondary ml10">
                  <Icon name="floppy-disk" /> Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

class AccountScreenEnhanced extends PureComponent {
  state = {
    loading: false
  };

  initialValues = memoize(
    ({
      name,
      email,
      born_at,
      gender,
      phone,
      addr_cep,
      addr_state,
      addr_city,
      addr_street,
      addr_number,
      addr_complement,
      addr_district,
      cpf,
      avatar_128x0
    }) => {
      const values = {
        name,
        email,
        born_at,
        gender,
        phone,
        addr_cep,
        addr_state,
        addr_city,
        addr_street,
        addr_number,
        addr_complement,
        addr_district,
        cpf,
        image: {
          default: avatar_128x0
        }
      };

      if (born_at) {
        values.born_at = moment(born_at, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }

      Object.keys(values).forEach(k => {
        if (values[k] === null) {
          values[k] = '';
        }
      });

      return values;
    }
  );

  onSubmit = values => {
    const { dispatchUpdateProfile, showMessage } = this.props;

    const profileValues = { ...values };

    if (profileValues.image.file) {
      profileValues.avatar = profileValues.image.file;
    }

    delete profileValues.image;

    if (profileValues.born_at) {
      profileValues.born_at = moment(
        profileValues.born_at,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
    }

    this.setState({ loading: true }, () => {
      updateProfile(profileValues)
        .then(({ data }) => {
          dispatchUpdateProfile(data);
          showMessage('Atualizado com sucesso!', 'secondary');
          window.location.reload();
        })
        .catch(res => {
          showMessage('Error ao atualizar', 'danger');
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  onCancel = () => {
    if (window.confirm('Tem certeza que deseja cancelar as alterações?')) {
      this.props.history.push('/');
    }
  };

  render() {
    const {
      account: { loaded, data },
      history
    } = this.props;

    const { loading } = this.state;

    if (!loaded) {
      return <Loading visible />;
    }

    return (
      <AccountScreen
        loading={loading}
        initialValues={this.initialValues(data.profile)}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        history={history}
      />
    );
  }
}

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps, {
  dispatchUpdateProfile,
  showMessage,
  unregisterAccount
})(withRouter(AccountScreenEnhanced));
