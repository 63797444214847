import _ from 'lodash';
import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import useVersion from '~/hooks/use-version';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '~/components/button';
import './style.scss';
import './ranking.scss';
import {
  unregisterAccount,
  updateProfile as dispatchUpdateProfile
} from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';
import { getRankings } from '~/api/account';
import Loading from '~/components/loading';
import api from '~/api/api';

export const enhanceAccounts = (accounts, loggedAccount) => {
  return accounts.map(account => {
    let nextIsSequence = true;
    let isLoggedACcount =
      _.get(loggedAccount, 'data.profile.account') === account.account;

    if (accounts.indexOf(account) !== accounts.length - 1) {
      nextIsSequence =
        account.position + 1 ===
        accounts[accounts.indexOf(account) + 1].position;
    }
    return {
      ...account,
      nextIsSequence,
      isLoggedACcount
    };
  });
};

const RankingsScreen = ({ rankingsGrouped, loading }) => {
  const version = useVersion();

  const renderRankings = rankings => {
    const organization = rankings[0].organization;

    return (
      <article key={organization.id} className="ranking-organization">
        <div className="flex">
          <div className="w300 no-flex text-center">
            <img
              src={organization.avatar_128x0}
              width={64}
              height={64}
              alt={organization.name}
              title={organization.name}
              className="rounded org-image"
            />
            <h2 className="ranking-organization-title">{organization.name}</h2>
            <p className="org-points">
              {organization.points.toLocaleString()} pontos
            </p>
          </div>

          <div>
            {rankings.map(ranking => (
              <div key={ranking.ranking.pk} className="ranking">
                <header className="ranking-header">
                  <h2>{ranking.ranking.title}</h2>

                  <time dateTime={ranking.ranking.open_at}>
                    {moment(ranking.ranking.open_at).format('DD/MM/YYYY')} a{' '}
                    {version === 'mobile' && <br />}
                    {moment(ranking.ranking.close_at).format('DD/MM/YYYY')}
                  </time>
                </header>

                <ol className="position-list">
                  {ranking.accounts.map(account => {
                    const cls = ['position'];

                    if (!account.nextIsSequence) {
                      cls.push('with-dot');
                    }

                    if (account.position === 1) {
                      cls.push('first-account');
                    }

                    if (account.isLoggedACcount) {
                      cls.push('my-position');
                    }

                    return (
                      <li key={account.pk} className={cls.join(' ')}>
                        <div className="pos-name">
                          <div className="badge-count rounded">
                            {account.position}º
                          </div>

                          <div className="position-avatar-wrapper">
                            <img
                              src={account.avatar_128x0}
                              alt="Avatar"
                              className="rounded position-avatar"
                            />

                            {!account.nextIsSequence ? (
                              <div className="position-dots">
                                <div>.</div>
                                <div>.</div>
                                <div>.</div>
                              </div>
                            ) : null}
                          </div>

                          <span className="position-title">{account.name}</span>
                        </div>

                        <div>
                          <span className="position-points">
                            {account.points.toLocaleString()}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ol>

                <div className="text-right">
                  <Button
                    color="primary"
                    className="mr20"
                    to={`/rankings/${ranking.ranking.pk}`}
                  >
                    {ranking.ranking.status === 'opened' && 'Placar Parcial'}
                    {ranking.ranking.status === 'closed' && 'Placar Final'}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
    );
  };

  if (loading) {
    return (
      <div className="account-screen mb20">
        <div className="section-wrapper">
          <Loading inline visible />
        </div>
      </div>
    );
  }

  const openedRankings = _.get(rankingsGrouped, 'opened', []);
  const closedRankings = _.get(rankingsGrouped, 'closed', []);

  return (
    <>
      <div className="account-screen mb20">
        <div className="section-wrapper">
          <h2>Rankings Ativos</h2>
          {openedRankings.length > 0 && (
            <p>Essa é sua lista de rankings ativos no momento.</p>
          )}

          {openedRankings.length === 0 && (
            <p>Não há rankings ativos no momento.</p>
          )}
        </div>

        {openedRankings.map(renderRankings)}
      </div>

      <div className="account-screen mb20">
        <div className="section-wrapper">
          <h2>Rankings Encerrados</h2>
          {closedRankings.length > 0 && (
            <p>Essa é sua lista de rankings que já foram finalizados.</p>
          )}

          {closedRankings.length === 0 && (
            <p>Não há rankings encerrados no momento.</p>
          )}
        </div>

        {closedRankings.map(renderRankings)}
      </div>
    </>
  );
};

class RankingsScreenEnhanced extends PureComponent {
  state = {
    nextUrl: null,
    loading: false,
    rankings: []
  };

  componentDidMount() {
    this.load();
  }

  handleResult = ({ data }) => {
    // getRanking(data[0].ranking.pk);
    // getRankingAccounts(data[0].ranking.pk);

    this.setState({
      rankings: [...this.state.rankings, ...data]
    });
  };

  removeLoading = () => {
    this.setState({ loading: false });
  };

  next = () => {
    this.setState({ loading: true }, () => {
      api
        .get(this.state.nextUrl)
        .then(this.handleResult)
        .finally(this.removeLoading);
    });
  };

  load() {
    this.setState({ loading: true }, () => {
      getRankings()
        .then(this.handleResult)
        .finally(this.removeLoading);
    });
  }

  group = memoize((rankings, account) => {
    const organizations = _.get(
      account,
      'data.organization_is_active_set',
      []
    ).reduce((memo, org) => {
      memo[org.id] = org;
      return memo;
    }, {});

    let resp = rankings
      .map(ranking => ({
        ...ranking,
        accounts: enhanceAccounts(ranking.accounts, account),
        organization: organizations[ranking.ranking.organization]
      }))
      .filter(ranking => !!ranking.organization);

    resp = _.groupBy(resp, ranking => ranking.ranking.status);

    resp = _.fromPairs(
      _.map(resp, (rankings, status) => {
        return [
          status,
          Object.values(
            _.groupBy(rankings, ranking => ranking.ranking.organization)
          )
        ];
      })
    );

    return resp;
  });

  render() {
    const { rankings, nextUrl, loading } = this.state;
    const { account } = this.props;

    const avatar = _.get(account, 'data.profile.avatar_128x0');

    return (
      <RankingsScreen
        rankingsGrouped={this.group(rankings, account)}
        hasNext={!!nextUrl}
        onNext={this.next}
        loading={loading}
        avatar={avatar}
      />
    );
  }
}

const mapStateToProps = ({ account }) => ({ account });

export default connect(mapStateToProps, {
  dispatchUpdateProfile,
  showMessage,
  unregisterAccount
})(withRouter(RankingsScreenEnhanced));
