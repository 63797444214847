import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCourses } from '~/api/training';

import { showMessage } from '~/store/ducks/messageBar';

import CustomLoading from '~/components/custom-loading';
import PageHeader from '~/components/page-header';
import SearchInput from '~/components/search-input';
import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import CourseItem from '~/components/course-item';
import Breadcrumb from '~/components/breadcrumb';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import Select from '~/components/form/select';

import { StyledCoursesHistory } from './styles';

function CoursesHistory({ organization, history, showMessage }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;

  const [pageTitle, setPageTitle] = useState('Meu histórico');
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);

  const [courses, setCourses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [status, setStatus] = useState('all');

  const handleBack = () => {
    history.push('/training');
  };

  const getCourseList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    if (filters.name !== '') {
      setSearchLoading(true);
    }

    getCourses(activeOrganizationId, filters, page)
      .then(response => {
        const { title, results, next, breadcrumb } = response.data;

        const bread = breadcrumb.map(link => {
          link.id = link.pk;
          delete link.pk;
          return link;
        });

        bread[0].id = `${Date.now()}1`;
        bread[0].route = '/training';

        bread[1].id = `${Date.now()}2`;
        bread[1].route = '/training/courses-history';

        setPageTitle(title);
        setCourses(results);
        setNextPage(next);
        setBreadcrumbLinks(bread);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
        setSearchLoading(false);
      });
  };

  const handleStatus = event => {
    const { value } = event.target;

    setStatus(value);

    getCourseList({
      name: search,
      only_subscribed: true,
      only_finished: true,
      only_graduated: value
    });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getCourseList({
        name: value,
        only_subscribed: true,
        only_finished: true,
        only_graduated: status
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const loadMoreCourses = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      only_subscribed: true,
      only_finished: true,
      only_graduated: status
    };

    getCourses(activeOrganizationId, filters, currentPage + 1)
      .then(response => {
        setCourses(lastPerformanceList => [
          ...lastPerformanceList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getCourseList({
      name: search,
      only_subscribed: true,
      only_finished: true,
      only_graduated: status
    });
  }, []);

  return (
    <StyledCoursesHistory>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side"></div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {(!loading || searchLoading) && (
          <PageHeader>
            <div className="text-info">
              <h1>{pageTitle}</h1>
              <div className="actions">
                <SearchInput
                  value={search}
                  onChange={handleSearch}
                  placeholder="Busque por nome"
                />
                <Select value={status} onChange={handleStatus}>
                  <option value="all">Todos</option>
                  <option value="finished">Concluídos</option>
                  <option value="not_finished">Não Concluídos</option>
                </Select>
              </div>
            </div>
          </PageHeader>
        )}

        {(!loading || searchLoading) && (
          <Breadcrumb
            links={breadcrumbLinks}
            compressionStart={10}
            onClickCallback={() => {}}
          />
        )}

        <div className="course-list">
          {!loading &&
            courses.map(course => (
              <CourseItem
                key={course.pk}
                courseId={course.pk}
                title={course.title}
                image={course.cover}
                startAt={course.start_at}
                finishAt={course.finish_at}
                stepCount={course.steps_count}
                stepLabel={
                  course.steps_count !== 1
                    ? course.step_name_plural
                    : course.step_name
                }
                trailCount={course.trails_count}
                trailLabel={
                  course.trails_count !== 1
                    ? course.trail_name_plural
                    : course.trail_name
                }
              />
            ))}

          {!loading && courses.length === 0 && (
            <EmptyList message="Nenhuma curso encontrado." />
          )}
        </div>

        {loadingMore && (
          <CustomLoading type="spin" height={36} width={36} fluid />
        )}

        {loading && (
          <CustomLoading
            type="spin"
            height={56}
            width={56}
            fluid
            className="screen-loading"
          />
        )}
      </div>

      <InfiniteScroll
        fetchMore={loadMoreCourses}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledCoursesHistory>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoursesHistory));
